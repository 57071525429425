import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import Validator from "validator";
import _ from "lodash";
//Material Ui
import { Box, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
//ContextAPI
import { useAuth, useStepperContext } from "../../../../contextAPI";
//Helpers
import {
  frenshCountry,
  gender,
  SnackbarHolder,
  Startup,
} from "../../../../helpers";
//Assets
import { Svg } from "../../../../assets";
//Hooks
import { useWindowDimensions } from "../../../../hooks";
//services
import { createUser, getUserMe, signIn } from "../../../../services";
//Components
import {
  AutoComplete,
  Button,
  DatePicker,
  Form,
  Input,
  Select,
} from "../../../../components";
//Styles
import { ButtonText, Container, Labeltext } from "./userForm.styles";
//Types
import { UserFormState } from "./userForm.types";

const maxDate = new Date().getFullYear() - 18;
const minDate = new Date().getFullYear() - 100;

const UserForm = () => {
  const stepper = useStepperContext();
  const auth = useAuth();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  //states
  const methods = useForm<UserFormState>({
    defaultValues: {
      firstName: "",
      lastName: "",
      gender: "",
      function: "",
      email: "",
      password: "",
      nationality: {
        code: "",
        name: "",
      },
      country: {
        code: "",
        name: "",
      },
      birthDay: "",
      urlLinkedIn: "",
      showPassword: false,
    },
  });

  const dataWatch = useWatch({ control: methods.control });
  //queries & mutations
  const signUp = useMutation(createUser);
  const login = useMutation(signIn);
  //useEffects
  useEffect(() => {
    methods.clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataWatch]);

  useEffect(() => {
    if (!auth.acceptedCGU) {
      navigate(`/`, {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //memos
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deviceHeight = useMemo(() => _.clone(height), []);
  //funtions
  const onFailSubmit = (data: any) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (key !== "undefined")
        SnackbarHolder.alert("error", "Des champs obligatoires non remplis");
    });
  };
  const onClickShowPassword = () => {
    methods.setValue("showPassword", !dataWatch.showPassword);
  };
  const onSubmit: SubmitHandler<UserFormState> = async (data) => {
    const dataToSend = {
      role: Startup,
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      password: data.password,
      country: data.country?.name,
      gender: data.gender,
      function: data.function,
      nationality: data.nationality?.name,
      birthday: data.birthDay,
      linkedin: data.urlLinkedIn,
    };
    if (data.nationality?.name === "") {
      methods.setError("nationality", {
        type: "custom",
        message: "Ce champ est requis.",
      });
      return;
    }
    if (data.country?.name === "") {
      methods.setError("country", {
        type: "custom",
        message: "Ce champ est requis.",
      });
      return;
    }

    if (
      data.urlLinkedIn &&
      data.urlLinkedIn !== "" &&
      !Validator.isURL(data.urlLinkedIn)
    ) {
      SnackbarHolder.alert("error", "Lien invalide.");
      methods.setError("urlLinkedIn", {
        type: "custom",
        message: "Lien invalide.",
      });
      return;
    }
    try {
      const res = await signUp.mutateAsync(dataToSend);
      if (res?.status === 204) {
        try {
          const logRes = await login.mutateAsync({
            email: dataWatch.email,
            password: dataWatch.password,
          });
          if (logRes?.status === 200) {
            try {
              const getUser = await getUserMe({
                token: logRes.data.data.access_token,
              });
              if (getUser.status === 200) {
                SnackbarHolder.alert("success", "Compte créé avec succès");
                sessionStorage.setItem("token", logRes.data.data.access_token);
                sessionStorage.setItem("role", Startup);
                auth.signIn({
                  token: logRes.data.data.access_token,
                  refreshToken: logRes.data.data.refresh_token,
                  role: Startup,
                });
                navigate("/form-startup", { replace: true });
                stepper.handleComplete();
              }
            } catch (error: any) {
              throw new Error(error);
            }
          }
        } catch (error: any) {
          throw new Error(error);
        }
      } else {
        SnackbarHolder.alert("error", res.data.errors[0].message);
        res.data.errors.forEach((error: any) => {
          methods.setError(
            error.extensions.field,
            {
              type: "custom",
              message: error.message,
            },
            { shouldFocus: true }
          );
        });
      }
    } catch (error: any) {
      SnackbarHolder.alert("error", "Une erreur s'est produite");
    }
  };
  const handleBack = () => {
    navigate("/", { replace: true });
  };
  return (
    <>
      <FormProvider {...methods}>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Container
              minHeight={width < 490 ? deviceHeight - 400 : height - 420}
              maxHeight={width < 490 ? deviceHeight - 400 : height - 420}
            >
              <Typography
                fontWeight={700}
                fontSize={16}
                color="#616161"
                fontFamily="Mont"
                pb={2}
              >
                Vos informations personnelles
              </Typography>
              <Input
                name="lastName"
                minWidth={360}
                label={
                  <Box display="flex">
                    <Labeltext color="rgba(43, 45, 50, 0.64)">Nom</Labeltext>
                    <Labeltext color="#EF4B12">*</Labeltext>
                  </Box>
                }
                rules={{
                  required: "Ce champ est requis.",
                  maxLength: { value: 25, message: "Max 25 caractères" },
                  minLength: { value: 2, message: "Min 2 caractères" },
                }}
              />
              <Input
                name="firstName"
                minWidth={360}
                autoComplete="off"
                label={
                  <Box display="flex">
                    <Labeltext color="rgba(43, 45, 50, 0.64)">Prénom</Labeltext>
                    <Labeltext color="#EF4B12">*</Labeltext>
                  </Box>
                }
                rules={{
                  required: "Ce champ est requis.",
                  maxLength: { value: 25, message: "Max 25 caractères" },
                  minLength: { value: 2, message: "Min 2 caractères" },
                }}
              />
              <Select
                name="gender"
                label={
                  <Box display="flex">
                    <Labeltext color="rgba(43, 45, 50, 0.64)">Genre</Labeltext>
                    <Labeltext color="#EF4B12">*</Labeltext>
                  </Box>
                }
                data={gender}
                getOptionLabel={(item) => item.label}
                getOptionValue={(item) => item.value}
                rules={{
                  required: "Ce champ est requis.",
                }}
              />
              <Input
                name="function"
                minWidth={360}
                autoComplete="off"
                label={
                  <Box display="flex">
                    <Labeltext color="rgba(43, 45, 50, 0.64)">
                      Fonction
                    </Labeltext>
                    <Labeltext color="#EF4B12">*</Labeltext>
                  </Box>
                }
                rules={{
                  required: "Ce champ est requis.",
                }}
              />
              <Input
                name="email"
                minWidth={360}
                autoComplete="off"
                label={
                  <Box display="flex">
                    <Labeltext color="rgba(43, 45, 50, 0.64)">
                      Adresse Email
                    </Labeltext>
                    <Labeltext color="#EF4B12">*</Labeltext>
                  </Box>
                }
                rules={{
                  required: "Ce champ est requis.",
                  validate: (value: string) =>
                    Validator.isEmail(value) || "Adresse e-mail invalide.",
                }}
              />
              <Input
                name="password"
                minWidth={360}
                autoComplete="off"
                label={
                  <Box display="flex">
                    <Labeltext color="rgba(43, 45, 50, 0.64)">
                      Mot de passe
                    </Labeltext>
                    <Labeltext color="#EF4B12">*</Labeltext>
                  </Box>
                }
                type={dataWatch.showPassword ? "text" : "password"}
                rules={{
                  required: "Ce champ est requis.",
                  pattern: {
                    value:
                      /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/gm,
                    message:
                      "Mot de passe doit être d'une longueur minimum de 8 caractères avec au moins 1 lettre minuscule, 1 lettre majuscule, 1 chiffre et un caractère spécial",
                  },
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={onClickShowPassword}
                      edge="end"
                    >
                      {dataWatch.showPassword ? (
                        <VisibilityOff fontSize="small" />
                      ) : (
                        <Visibility fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <AutoComplete
                name="nationality"
                label={
                  <Box display="flex">
                    <Labeltext color="rgba(43, 45, 50, 0.64)">
                      Nationalité du fondateur
                    </Labeltext>
                    <Labeltext color="#EF4B12">*</Labeltext>
                  </Box>
                }
                placeholder="Nationalité du fondateur"
                data={frenshCountry}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option, value) => option.code === value.code}
                rules={{
                  required: "Ce champ est requis.",
                }}
              />
              <AutoComplete
                name="country"
                label={
                  <Box display="flex">
                    <Labeltext color="rgba(43, 45, 50, 0.64)">
                      Pays de résidence
                    </Labeltext>
                    <Labeltext color="#EF4B12">*</Labeltext>
                  </Box>
                }
                placeholder="Nationalité du fondateur"
                data={frenshCountry}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option, value) => option.code === value.code}
                rules={{
                  required: "Ce champ est requis.",
                }}
              />
              <DatePicker
                name="birthDay"
                disableFuture={true}
                maxDate={new Date(`12/31/${maxDate}`)}
                minDate={new Date(`01/01/${minDate}`)}
                initValue={new Date("01/01/1990")}
                label={
                  <Box display="flex">
                    <Labeltext color="rgba(43, 45, 50, 0.64)">
                      Date de naissance
                    </Labeltext>
                    <Labeltext color="#EF4B12">*</Labeltext>
                  </Box>
                }
                rules={{
                  required: "Ce champ est requis.",
                  validate: (value: string) =>
                    (Validator.isDate(value) && new Date(value) < new Date()) ||
                    "Date invalide",
                }}
              />
              <Input
                name="urlLinkedIn"
                minWidth={360}
                autoComplete="off"
                label={
                  <Box display="flex">
                    <Labeltext color="rgba(43, 45, 50, 0.64)">
                      URL Linkedin
                    </Labeltext>
                  </Box>
                }
              />
            </Container>
          </Form>
          <Box display="flex" justifyContent="space-between" mt={2} width="90%">
            <Button
              variant="text"
              startIcon={<Svg.ArrowLeft />}
              onClick={handleBack}
            >
              {width < 490 ? null : <ButtonText>Précédent</ButtonText>}
            </Button>
            <Button
              variant="contained"
              color="primary"
              boxshadow="0px 5px 5px rgba(239, 75, 18, 0.2)"
              width={width < 490 ? 90 : 190}
              onClick={methods.handleSubmit(onSubmit, onFailSubmit)}
            >
              Suivant
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </>
  );
};

export default UserForm;

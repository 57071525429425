import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import Validator from "validator";
import _ from "lodash";
//Material Ui
import { Box, Typography } from "@mui/material";
//Assets
import { Svg } from "../../../../assets";
//ContextAPI
import { useAuth, useStepperContext } from "../../../../contextAPI";
//Services
import {
  getPartners,
  getStartupById,
  getUserMe,
  saveAccompagnement,
  startupTrackPage,
} from "../../../../services";
//Hooks
import { useWindowDimensions } from "../../../../hooks";
//helpers
import { Partner, SnackbarHolder } from "../../../../helpers";
//Components
import {
  Button,
  Form,
  Input,
  RadioGroup,
  Select,
} from "../../../../components";
//Styles
import { ButtonText, Container, Labeltext } from "./addSupport.styles";
//Types
import { AddSupportState, ParamsProps } from "./addSupport.types";

const AddSupport = () => {
  const auth = useAuth();
  const params: ParamsProps = useParams();
  const stepper = useStepperContext();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  //states
  const methods = useForm<AddSupportState>({
    defaultValues: {
      hadSupport: "no",
      partner: null,
      contract: null,
      name: null,
      email: null,
    },
  });
  const dataWatch = methods.watch();
  //queries & mutations
  const trackPage = useMutation(startupTrackPage);
  const addAccompagnement = useMutation(saveAccompagnement);
  useQuery(
    ["startup-edit", params.id],
    () => {
      if (!params.id || !auth.token) {
        return;
      }
      return getStartupById({ id: params.id, token: auth.token });
    },
    {
      enabled: auth.token ? true : false,
      onSuccess: (startup) => {
        if (!startup) {
          return;
        }
        methods.reset({
          hadSupport: startup.data.data.accompaniment === true ? "yes" : "no",
          partner: startup.data.data.partner,
          contract: startup.data.data.contact_ref,
          name: startup.data.data.other_partner_name,
          email: startup.data.data.other_partner_email,
        });
      },
    }
  );

  const getUser = useQuery(
    ["userMe", auth.token],
    () => {
      if (!auth.token) return;
      return getUserMe({ token: auth.token });
    },
    {
      enabled: auth.token ? true : false,
    }
  );

  //memos
  const user = useMemo(() => getUser?.data?.data?.data, [getUser]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deviceHeight = useMemo(() => _.clone(height), []);

  //useEffects
  useEffect(() => {
    methods.clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataWatch]);
  useEffect(() => {
    if (user && user.role === Partner) {
      navigate("/partner/form-company", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  //functions

  const onFailSubmit = (data: any) => {
    SnackbarHolder.alert("error", "Des champs obligatoires non remplis");
  };
  const onSubmit: SubmitHandler<AddSupportState> = async (data) => {
    if (!params.id || !auth.token) return;
    const accompagnementData = {
      accompaniment: data.hadSupport === "yes" ? true : false,
      partner: data.hadSupport === "yes" ? data.partner : null,
      contact_ref: data.hadSupport === "yes" ? data.contract : null,
      other_partner_name: data.hadSupport === "yes" ? data.name : null,
      other_partner_email: data.hadSupport === "yes" ? data.email : null,
    };
    try {
      const response = await addAccompagnement.mutateAsync({
        data: accompagnementData,
        startupId: params.id,

        token: auth.token,
      });
      if (response.status === 200) {
        try {
          const saveResponse = await trackPage.mutateAsync({
            data: {
              last_page: `/validation/${params.id}`,
            },
            token: auth.token,
          });
          if (saveResponse.status === 204) {
            SnackbarHolder.alert("success", "Données sauvegardées avec succès");
            stepper.handleComplete();
            navigate(`/validation/${params.id}`, { replace: true });
          }
        } catch (error: any) {
          throw new Error(error);
        }
      } else {
        SnackbarHolder.alert("error", "Une erreur s'est produite");
      }
    } catch (error: any) {
      SnackbarHolder.alert("error", "Une erreur s'est produite");
    }
  };
  const handleDraft = async () => {
    if (!params.id || !auth.token) return;
    const accompagnementData = {
      accompaniment: dataWatch.hadSupport === "yes" ? true : false,
      partner: dataWatch.hadSupport === "yes" ? dataWatch.partner : null,
      contact_ref: dataWatch.hadSupport === "yes" ? dataWatch.contract : null,
      other_partner_name:
        dataWatch.hadSupport === "yes" ? dataWatch.name : null,
      other_partner_email:
        dataWatch.hadSupport === "yes" ? dataWatch.email : null,
    };
    try {
      const response = await saveAccompagnement({
        data: accompagnementData,
        startupId: params.id,
        token: auth.token,
      });
      if (response.status === 200) {
        try {
          await trackPage.mutateAsync({
            data: {
              last_page: `/add-support/${params.id}`,
            },
            token: auth.token,
          });

          SnackbarHolder.alert("success", "Données sauvegardées avec succès");
        } catch (error: any) {
          throw new Error(error);
        }
      } else {
        SnackbarHolder.alert("error", response.data.errors[0].message);
      }
    } catch (error: any) {
      SnackbarHolder.alert("error", "Une erreur s'est produite");
    }
  };
  const handleBack = () => {
    navigate(`/edit-startup/${params.id}`, { replace: true });
    stepper.handleBack();
  };
  //queries and mutations
  const partnersList: any = useQuery(
    ["partner", auth.token],
    () => {
      if (!auth.token) return;
      return getPartners("partner", auth.token);
    },
    {
      keepPreviousData: true,
      onError: (error: any) => {
        auth.handlingErrorUnauthorized("partner");
      },
    }
  );

  //memos
  const partners = useMemo(
    (): any => {
      let finalPartners = partnersList?.data?.data?.data.map((partner: { name: any; id: any }) => {
        return { label: partner.name, value: partner.id };
      })
      let indexOfOther = finalPartners?.findIndex((p: { label: string; }) => p.label === "Autre");
      if (indexOfOther !== -1) {
        const otherObject = finalPartners?.splice(indexOfOther, 1)[0];
        finalPartners?.push(otherObject);
      }
      return finalPartners
    },
    [partnersList]
  );
  //renders
  const renderItemsIfYes = () => {
    if (dataWatch.hadSupport === "yes") {
      return (
        <Box display="flex" flexDirection="column" gap={2}>
          <Select
            name="partner"
            label={
              <Box display="flex">
                <Labeltext color="rgba(43, 45, 50, 0.64)">Lequel?</Labeltext>
                <Labeltext color="#EF4B12">*</Labeltext>
              </Box>
            }
            data={!partnersList.isLoading ? partners : []}
            getOptionLabel={(item: any) => item.label}
            getOptionValue={(item: any) => item.value}
            rules={{
              required: "Ce champ est requis",
            }}
          />
          <Input
            name="contract"
            minWidth={360}
            autoComplete="off"
            label={
              <Box display="flex">
                <Labeltext color="rgba(43, 45, 50, 0.64)">
                  Nom et prénom du contact référent
                </Labeltext>
                <Labeltext color="#EF4B12">*</Labeltext>
              </Box>
            }
            rules={{
              required: "Ce champ est requis",
            }}
          />
        </Box>
      );
    }
  };
  const renderItemsIfOther = () => {
    if (
      dataWatch.hadSupport === "yes" &&
      dataWatch.partner === process.env.REACT_APP_OTHER_PARTNER_ID
    ) {
      return (
        <Box display="flex" flexDirection="column" gap={2}>
          <Input
            name="name"
            minWidth={360}
            autoComplete="off"
            label={
              <Box display="flex">
                <Labeltext color="rgba(43, 45, 50, 0.64)">
                  Nom de la structure d’accompagnement
                </Labeltext>
                <Labeltext color="#EF4B12">*</Labeltext>
              </Box>
            }
            rules={{
              required: "Ce champ est requis",
            }}
          />
          <Input
            name="email"
            minWidth={360}
            autoComplete="off"
            label={
              <Box display="flex">
                <Labeltext color="rgba(43, 45, 50, 0.64)">
                  Email de la structure d’accompagnement
                </Labeltext>
                <Labeltext color="#EF4B12">*</Labeltext>
              </Box>
            }
            rules={{
              required: "Ce champ est requis",
              validate: (value: string) =>
                Validator.isEmail(value) || "Email invalide.",
            }}
          />
        </Box>
      );
    }
  };
  return (
    <>
      <FormProvider {...methods}>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Container
              minHeight={width < 490 ? deviceHeight - 400 : height - 420}
              maxHeight={width < 490 ? deviceHeight - 400 : height - 420}
            >
              <Typography
                fontWeight={700}
                fontSize={16}
                color="#616161"
                fontFamily="Mont"
                pb={2}
              >
                Accompagnement
              </Typography>
              <Typography fontWeight={400} fontSize={14} color="#616161">
                Avez-vous intégré un programme d’accompagnement? (Tout programme d’incubation, d’accélération présent ou passé ou autre y compris un soutien d’un business angel, Venture Builder…etc) *
              </Typography>
              <RadioGroup
                name="hadSupport"
                data={[
                  { label: "Oui", value: "yes" },
                  { label: "Non", value: "no" },
                ]}
                getOptionLabel={(item) => item.label}
                getOptionValue={(item) => item.value}
                rules={{
                  required: "Ce champ est requis",
                }}
              />
              {renderItemsIfYes()}
              {renderItemsIfOther()}
            </Container>
          </Form>
          <Box display="flex" justifyContent="space-between" mt={2} width="90%">
            <Button
              variant="text"
              startIcon={<Svg.ArrowLeft />}
              disabled={trackPage.isLoading || addAccompagnement.isLoading}
              onClick={handleBack}
            >
              {width < 490 ? null : <ButtonText>Précédent</ButtonText>}
            </Button>
            <Box display="flex" gap={1}>
              <Button
                variant="contained"
                boxshadow="0px 5px 5px rgba(172, 172, 172, 0.2)"
                width={width < 490 ? 110 : 190}
                loading={trackPage.isLoading || addAccompagnement.isLoading}
                onClick={handleDraft}
                sx={{ backgroundColor: "#E0E0E7", color: "#000000" }}
              >
                Sauvegarder
              </Button>
              <Button
                variant="contained"
                color="primary"
                boxshadow="0px 5px 5px rgba(239, 75, 18, 0.2)"
                width={width < 490 ? 110 : 190}
                loading={trackPage.isLoading || addAccompagnement.isLoading}
                onClick={methods.handleSubmit(onSubmit, onFailSubmit)}
              >
                Suivant
              </Button>
            </Box>
          </Box>
        </Box>
      </FormProvider>
    </>
  );
};

export default AddSupport;

import { useState, useMemo, Fragment, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import Validator from "validator";
//Material Ui
import { Box, IconButton, Link, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
//ContextAPI
import {
  FormHandlerProvider,
  useAuth,
  useStepperContext,
} from "../../../../contextAPI";
//Assets
import { Images, Svg } from "../../../../assets";
//Services
import {
  checkFlowsEligibility,
  getFields,
  getStartupById,
  getUserMe,
  updateUserMe,
} from "../../../../services";
//Hooks
import { useWindowDimensions } from "../../../../hooks";
//components
import {
  AutoComplete,
  Button,
  DatePicker,
  Form,
  Image,
  Input,
  ModalConfirmation,
} from "../../../../components";
//Helpers
import { country, SnackbarHolder, sortFieldsArray } from "../../../../helpers";
//Styles
import {
  ButtonText,
  Container,
  ContainerLeft,
  ContainerHalf,
  ContainerRight,
  Information,
  SectionContainer,
  SectionSubTitle,
  SectionTitle,
  DocumentBox,
  DocumentText,
  Labeltext,
} from "./validationStartup.styles";
//Types
import { ParamsProps, ValidationStartupState } from "./validationStartup.types";

const exeptionFields = [
  "id",
  "status",
  "logo",
  "user_created",
  "user_updated",
  "date_updated",
  "date_created",
  "list-m2m",
  "due_diligence",
  "accompaniment",
  "other_partner_name",
  "contact_ref",
  "other_partner_email",
  "partner",
  "name",
  "country",
  "website",
  "file",
  "select-dropdown-m2o",
  "age",
];
const ValidationStartup = () => {
  const auth = useAuth();
  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();
  const stepper = useStepperContext();
  const params: ParamsProps = useParams();

  //states
  const [open, setOpen] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEligible, setIsEligible] = useState<boolean>(false);
  const [paramsFields, setParamsFields] = useState<string>("");

  const methods = useForm<ValidationStartupState>({
    defaultValues: {
      firstName: "",
      lastName: "",
      nationality: null,
      country: null,
      birthDay: "",
      urlLinkedIn: "",
    },
  });

  //queries & mutations
  const startupFields = useQuery(
    ["fields", params.id, auth.token],
    () => {
      if (!auth.token) return;
      return getFields("startup", auth.token);
    },
    {
      onSuccess(fields) {
        let str = "";
        let arrayRepeater = fields?.data?.data.filter(
          (field: any) => field.meta.interface === "list-m2m"
        );
        arrayRepeater.forEach((element: any) => {
          str =
            str + `,${element.field}.*,${element.field}.${element.field}_id.*`;
        });

        let arrayOfMultiFiles = fields?.data?.data.filter(
          (field: any) => field.meta.interface === "files"
        );

        arrayOfMultiFiles.forEach((element: any) => {
          str =
            str + `,${element.field}.*,${element.field}.directus_files_id.*`;
        });
        let arrayOfFiles = fields?.data?.data.filter(
          (field: any) => field.meta.interface === "file"
        );
        arrayOfFiles.forEach((element: any) => {
          if (!exeptionFields.includes(element.field)) {
            str = str + `,${element.field}.*`;
          }
        });
        setParamsFields(str);
      },
    }
  );

  const dataStartup = useQuery(
    ["startup-edit", params.id, paramsFields],
    () => {
      if (!params.id || !auth.token) return;
      if (paramsFields === "") return;
      return getStartupById({
        id: params.id,
        moreFields: paramsFields,
        token: auth.token,
      });
    },
    {
      enabled: startupFields.isFetched,
    }
  );
  const getUser = useQuery(
    ["user", auth.token],
    () => {
      if (!auth.token) return;
      return getUserMe({ token: auth.token });
    },
    {
      onSuccess(data) {
        methods.reset({
          firstName: data?.data?.data.first_name,
          lastName: data?.data?.data.last_name,
          nationality: {
            name: data?.data?.data.nationality,
            code: data?.data?.data.nationality,
          },
          country: {
            name: data?.data?.data.country,
            code: data?.data?.data.country,
          },
          birthDay: data?.data?.data.birthday,
          urlLinkedIn: data?.data?.data.linkedin,
        });
      },
    }
  );

  const updateUser = useMutation(updateUserMe);
  const checkEligibility = useMutation(checkFlowsEligibility);

  //memos
  const fields = useMemo(
    () => startupFields?.data?.data?.data.sort(sortFieldsArray),
    [startupFields]
  );

  const startup = useMemo(() => dataStartup?.data?.data?.data, [dataStartup]);
  const user = useMemo(() => getUser?.data?.data?.data, [getUser]);
  const boxdirections = useMemo(
    () => (width < 490 ? "column" : "row"),
    [width]
  );
  const boxjustify = useMemo(
    () => (width < 490 ? "center" : "flex-start"),
    [width]
  );

  //useeffects
  useEffect(() => {
    dataStartup.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFields]);

  //funstions
  const handleClose = () => {
    setOpen(false);
  };
  const handleVisibility = () => {
    setIsEditable(!isEditable);
  };

  const onFailSubmit = (data: any) => {
    SnackbarHolder.alert("error", "Des champs obligatoires non remplis");
  };
  const onSubmit: SubmitHandler<ValidationStartupState> = async (data) => {
    if (!auth.token) return;
    const dataToSend = {
      first_name: data.firstName,
      last_name: data.lastName,
      country: data.country?.name,
      nationality: data.nationality?.name,
      birthday: data.birthDay,
      linkedin: data.urlLinkedIn,
    };
    try {
      const res = await updateUser.mutateAsync({
        data: dataToSend,
        token: auth.token,
      });
      if (res?.status === 200) {
        getUser.refetch();
        SnackbarHolder.alert("success", "Données mis à jour avec succés");
      }
    } catch (error: any) {
      SnackbarHolder.alert("error", "Une erreur s'est produite");
    }
    handleVisibility();
  };
  const validate: any = async () => {
    if (!auth.token) return;
    try {
      const res = await checkEligibility.mutateAsync({
        data: { id: params.id },
        token: auth.token,
      });
      if (res.status === 200) {
        if (res.data === "") return;
        if (res.data.data.data.status === "Non éligible") {
          setIsEligible(false);
        } else {
          setIsEligible(true);
        }
        setOpen(true);
      }
    } catch (error: any) {
      SnackbarHolder.alert("error", "Une erreur s'est produite");
    }
  };

  const handleDialogAction = () => {
    if (isEligible) {
      /**redirect to middle Office */
      navigate(`/no-draft/${params.id}`, { replace: true });
    } else if (!isEligible) {
      /**redirect to landingPage */
      auth.signOut(`${process.env.REACT_APP_LANDING_PAGE}`);
    }
  };
  // const handleICIClick = () => {
  //   /**redirect to FAQ */
  //   auth.signOut(`${process.env.REACT_APP_FAQ}`);
  // };

  const handleBack = () => {
    navigate(`/add-support/${params.id}`, { replace: true });
    stepper.handleBack();
  };
  const handleEdit = (direction: string) => {
    navigate(`/${direction}/${params.id}`, {
      replace: true,
    });
  };

  //renders
  const modalItemAccpeted = () => {
    return (
      <>
        <Typography
          fontFamily="Mont"
          fontSize={20}
          fontWeight={600}
          color="#000"
          textAlign="center"
        >
          Merci !
        </Typography>
        <Typography
          fontFamily="Mont"
          fontSize={16}
          fontWeight={400}
          textAlign="center"
          color="#999999"
        >
          Votre startup est éligible.
        </Typography>
        <Typography
          fontFamily="Mont"
          fontSize={16}
          fontWeight={400}
          textAlign="center"
          color="#999999"
        >
          Votre candidature a été partagée avec Digital Africa pour examen et
          évaluation conformément aux conditions générales d'utilisation. Votre
          structure d’accompagnement sera notifiée et elle sera invitée à nous
          soumettre son avis. A bientôt.
        </Typography>
      </>
    );
  };
  const modalItemRejected = () => {
    return (
      <>
        <Typography
          fontFamily="Mont"
          fontSize={20}
          fontWeight={600}
          color="#000"
          textAlign="center"
        >
          Nous vous remercions de l'intérêt que vous portez à l'initiative.
          Plusieurs critères sont pris en compte pour évaluer l’éligibilité de
          votre entreprise notamment la composante numérique, la nationalité des
          cofondateurs et la durée d’existence de la startup.
        </Typography>

        <Box display="flex" flexDirection="column" gap={1}>
          <Typography
            fontFamily="Mont"
            fontSize={20}
            fontWeight={600}
            color="#000"
            textAlign="center"
          >
            Malheureusement, votre startup n’est pas éligible.
          </Typography>
          <Typography
            fontFamily="Mont"
            fontSize={16}
            fontWeight={600}
            color="#EF4B12"
            textAlign="center"
            // sx={{ cursor: "pointer" }}
            // onClick={handleICIClick}
          >
            Nous vous invitons à découvrir les autres programmes et actions
            Digital Africa qui pourraient correspondre à votre projet.
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <Link
            target="_blank"
            href="https://www.linkedin.com/company/digitalafrica/"
          >
            <Svg.LinkedIn />
          </Link>
          <Link
            target="_blank"
            href="https://www.facebook.com/Digitalafrica.co/"
          >
            <Svg.Facebook />
          </Link>
          <Link target="_blank" href="https://twitter.com/digitalafrica__">
            <Svg.Twitter />
          </Link>
        </Box>
      </>
    );
  };
  const renderPersonalInformation = () => {
    if (isEditable) {
      return (
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <SectionTitle>Informations personnel</SectionTitle>
              <IconButton
                onClick={methods.handleSubmit(onSubmit, onFailSubmit)}
              >
                <Svg.ConfirmInput />
              </IconButton>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              maxHeight={width > 490 ? (height - 550) / 2 : "auto"}
              minHeight={width > 490 ? (height - 550) / 2 : "auto"}
              pt={1}
              overflow="auto"
            >
              <Input
                name="lastName"
                minWidth={200}
                label={
                  <Box display="flex">
                    <Labeltext color="rgba(43, 45, 50, 0.64)">Nom</Labeltext>
                    <Labeltext color="#EF4B12">*</Labeltext>
                  </Box>
                }
                rules={{
                  required: "Ce champ est requis.",
                }}
              />
              <Input
                name="firstName"
                minWidth={200}
                label={
                  <Box display="flex">
                    <Labeltext color="rgba(43, 45, 50, 0.64)">Prénom</Labeltext>
                    <Labeltext color="#EF4B12">*</Labeltext>
                  </Box>
                }
                rules={{
                  required: "Ce champ est requis.",
                }}
              />

              <AutoComplete
                name="nationality"
                label={
                  <Box display="flex">
                    <Labeltext color="rgba(43, 45, 50, 0.64)">
                      Nationalité du fondateur
                    </Labeltext>
                    <Labeltext color="#EF4B12">*</Labeltext>
                  </Box>
                }
                placeholder="Nationalité du fondateur"
                data={country}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option, value) => option.name === value.name}
                rules={{
                  required: "Ce champ est requis.",
                }}
              />
              <AutoComplete
                name="country"
                label={
                  <Box display="flex">
                    <Labeltext color="rgba(43, 45, 50, 0.64)">
                      Pays de résidence
                    </Labeltext>
                    <Labeltext color="#EF4B12">*</Labeltext>
                  </Box>
                }
                placeholder="Nationalité du fondateur"
                data={country}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option, value) => option.name === value.name}
                rules={{
                  required: "Ce champ est requis.",
                }}
              />
              <DatePicker
                name="birthDay"
                label={
                  <Box display="flex">
                    <Labeltext color="rgba(43, 45, 50, 0.64)">
                      Date de naissance
                    </Labeltext>
                    <Labeltext color="#EF4B12">*</Labeltext>
                  </Box>
                }
                rules={{
                  required: "Ce champ est requis.",
                  validate: (value: string) =>
                    (Validator.isDate(value) && new Date(value) < new Date()) ||
                    "Date invalide",
                }}
              />
              <Input
                name="urlLinkedIn"
                minWidth={200}
                label={
                  <Box display="flex">
                    <Labeltext color="rgba(43, 45, 50, 0.64)">
                      URL Linkedin
                    </Labeltext>
                  </Box>
                }
              />
            </Box>
          </Form>
        </FormProvider>
      );
    } else if (!isEditable && !getUser.isLoading) {
      return (
        <>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <SectionTitle>Informations personnelles</SectionTitle>
            <IconButton onClick={handleVisibility}>
              <Svg.EditIcon />
            </IconButton>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            maxHeight={width > 490 ? (height - 550) / 2 : "auto"}
            minHeight={width > 490 ? (height - 550) / 2 : "auto"}
            pt={1}
            overflow="auto"
          >
            <Box display="flex" flexDirection="row" gap={1}>
              <SectionSubTitle>Nom:</SectionSubTitle>
              <Information>{user.last_name}</Information>
            </Box>
            <Box display="flex" flexDirection="row" gap={1}>
              <SectionSubTitle>Prénom:</SectionSubTitle>
              <Information>{user.first_name}</Information>
            </Box>
            <Box display="flex" flexDirection="row" gap={1}>
              <SectionSubTitle>Nationalité du fondateur:</SectionSubTitle>
              <Information>{user.nationality}</Information>
            </Box>
            <Box display="flex" flexDirection="row" gap={1}>
              <SectionSubTitle>Pays de résidence:</SectionSubTitle>
              <Information>{user.country}</Information>
            </Box>
            <Box display="flex" flexDirection="row" gap={1}>
              <SectionSubTitle>Date de naissance:</SectionSubTitle>
              <Information>{user.birthday}</Information>
            </Box>
            {user.linkedin && (
              <Box display="flex" flexDirection="row" gap={1}>
                <SectionSubTitle>LinkedIn:</SectionSubTitle>
                <Information>
                  <Link target="_blank" href={user.linkedin}>
                    {user.linkedin}
                  </Link>
                </Information>
              </Box>
            )}
          </Box>
        </>
      );
    }
  };
  const renderMultifiles = () => {
    if (fields.length < 0) return;
    let arrayOfMultiFiles = fields.filter(
      (field: any) => field.meta.interface === "files"
    );
    if (!startup) return;
    // eslint-disable-next-line array-callback-return
    return arrayOfMultiFiles.map((array: any) => {
      if (startup[array.field] !== undefined) {
        return startup[array.field].map((file: any, index: number) => {
          return (
            <DocumentBox
              key={index}
              target="_blank"
              href={`${process.env.REACT_APP_DIRECTUS_URL}/assets/${file.directus_files_id.id}?access_token=${process.env.REACT_APP_ACCESS_TOKEN}`}
            >
              {file.directus_files_id.type === "application/pdf" ? (
                <Svg.PDFIcon />
              ) : (
                <Svg.ExelIcon />
              )}
              <DocumentText>{file.directus_files_id.title}</DocumentText>
            </DocumentBox>
          );
        });
      }
    });
  };
  const renderFile = () => {
    if (fields.length < 0) return;
    const arrayofFiles = fields.filter(
      (field: any) => field.meta.interface === "file"
    );
    if (!startup) return;
    // eslint-disable-next-line array-callback-return
    return arrayofFiles.map((file: any, index: number) => {
      if (startup[file.field] != null) {
        return (
          <DocumentBox
            key={index}
            target="_blank"
            href={`${process.env.REACT_APP_DIRECTUS_URL}/assets/${
              startup[file.field].id
            }?access_token=${process.env.REACT_APP_ACCESS_TOKEN}`}
          >
            {startup[file.field].type === "application/pdf" ? (
              <Svg.PDFIcon />
            ) : (
              <Svg.ExelIcon />
            )}
            <DocumentText>{startup[file.field].title}</DocumentText>
          </DocumentBox>
        );
      }
    });
  };

  if (
    startupFields.isLoading ||
    getUser.isLoading ||
    dataStartup.isLoading ||
    !getUser.isFetched ||
    !dataStartup.isFetched
  ) {
    return <CircularProgress />;
  }
  return (
    <FormHandlerProvider
      loading={
        startupFields.isLoading || getUser.isLoading || dataStartup.isLoading
      }
    >
      <Container
        minHeight={width < 490 ? height - 380 : height - 420}
        maxHeight={width < 490 ? height - 380 : height - 420}
      >
        <SectionContainer
          minHeight={width < 490 ? height - 380 : height - 420}
          maxHeight={width < 490 ? height - 380 : height - 420}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <ContainerLeft
              maxHeight={width > 490 ? (height - 460) / 2 : "auto"}
              minHeight={width > 490 ? (height - 460) / 2 : "auto"}
            >
              {renderPersonalInformation()}
            </ContainerLeft>
            <ContainerLeft
              maxHeight={width > 490 ? (height - 460) / 2 : "auto"}
              minHeight={width > 490 ? (height - 460) / 2 : "auto"}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <SectionTitle>Accompagnement</SectionTitle>
                <IconButton onClick={() => handleEdit("add-support")}>
                  <Svg.EditIcon />
                </IconButton>
              </Box>
              {!dataStartup.isLoading && startup && (
                <>
                  {startup.accompaniment && (
                    <Box display="flex" flexDirection="row" gap={1}>
                      <SectionSubTitle>Contact référent:</SectionSubTitle>
                      <Information>
                        {dataStartup.isLoading ? "" : startup.contact_ref}
                      </Information>
                    </Box>
                  )}
                  <Box display="flex" flexDirection="row" gap={1}>
                    <SectionSubTitle>
                      Avez-vous intégré un programme d’accompagnement:
                    </SectionSubTitle>
                    <Information>
                      {dataStartup.isLoading
                        ? ""
                        : startup.accompaniment
                        ? "oui"
                        : "non"}
                    </Information>
                  </Box>
                </>
              )}
            </ContainerLeft>
          </Box>
          <ContainerRight
            maxHeight={width > 1060 ? height - 450 : "auto"}
            minHeight={width > 1060 ? height - 450 : "auto"}
          >
            <ContainerHalf>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignContent="center"
              >
                <SectionTitle>Description de la Startup</SectionTitle>
                <IconButton onClick={() => handleEdit("edit-startup")}>
                  <Svg.EditIcon />
                </IconButton>
              </Box>
              <Box></Box>
              {!dataStartup.isLoading && startup && (
                <Box
                  display="flex"
                  flexDirection={boxdirections}
                  alignItems={boxjustify}
                  gap={1}
                >
                  <Image
                    src={
                      startup.logo
                        ? `${process.env.REACT_APP_DIRECTUS_URL}/assets/${startup.logo}?access_token=${process.env.REACT_APP_ACCESS_TOKEN}`
                        : Images.DefaultLogo
                    }
                    height={80}
                    width={80}
                    borderRadius={50}
                  />
                  <Box display="flex" flexDirection="column">
                    <SectionSubTitle>
                      {dataStartup.isLoading ? "" : startup.name}
                    </SectionSubTitle>
                    <Box display="flex" flexDirection="row" gap={1}>
                      <SectionSubTitle>Pays d’origine:</SectionSubTitle>
                      <Information>
                        {dataStartup.isLoading ? "" : startup.country}
                      </Information>
                    </Box>
                    {startup.website && (
                      <Box display="flex" flexDirection="row" gap={1}>
                        <SectionSubTitle>URL:</SectionSubTitle>
                        <Information>
                          <Link
                            target="_blank"
                            href={dataStartup.isLoading ? "#" : startup.website}
                          >
                            {dataStartup.isLoading ? "" : startup.website}
                          </Link>
                        </Information>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
              {!startupFields.isLoading &&
                !dataStartup.isLoading &&
                startup &&
                fields.map(
                  (startupField: any, index: number) =>
                    !exeptionFields.includes(startupField.field) && (
                      <Fragment key={index}>
                        {startupField.meta.interface === "input" &&
                          startup[startupField.field] !== "" &&
                          startup[startupField.field] !== 0 &&
                          startup[startupField.field] != null && (
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap={1}
                              flexWrap="wrap"
                            >
                              <SectionSubTitle>
                                {startupField.meta.note}
                              </SectionSubTitle>
                              <Information>
                                {startup[startupField.field]}
                              </Information>
                            </Box>
                          )}
                        {(startupField.meta.interface ===
                          "select-multiple-checkbox" ||
                          startupField.meta.interface ===
                            "select-multiple-dropdown") &&
                          startup[startupField.field].length > 0 && (
                            <Box display="flex" flexDirection="column" gap={1}>
                              <SectionSubTitle>
                                {startupField.meta.note}
                              </SectionSubTitle>
                              <Information>
                                {new Intl.ListFormat("en-GB", {
                                  style: "narrow",
                                  type: "unit",
                                }).format(startup[startupField.field])}
                              </Information>
                            </Box>
                          )}

                        {startupField.meta.interface === "input-multiline" &&
                          startup[startupField.field] && (
                            <Box display="flex" flexDirection="column" gap={1}>
                              <SectionSubTitle>
                                {startupField.meta.note}
                              </SectionSubTitle>
                              <Information>
                                {startup[startupField.field]}
                              </Information>
                            </Box>
                          )}
                        {startupField.meta.interface === "datetime" &&
                          startup[startupField.field] && (
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap={1}
                              flexWrap="wrap"
                            >
                              <SectionSubTitle>
                                {startupField.meta.note}
                              </SectionSubTitle>
                              <Information>
                                {startup[startupField.field]}
                              </Information>
                            </Box>
                          )}
                        {startupField.meta.interface === "select-dropdown" &&
                          startup[startupField.field] && (
                            <Box display="flex" flexDirection="column" gap={1}>
                              <SectionSubTitle>
                                {startupField.meta.note}
                              </SectionSubTitle>
                              <Information>
                                {startup[startupField.field]}
                              </Information>
                            </Box>
                          )}
                        {startupField.meta.interface === "boolean" &&
                          startup[startupField.field] && (
                            <Box display="flex" flexDirection="column" gap={1}>
                              <SectionSubTitle>
                                {startupField.meta.note}
                              </SectionSubTitle>
                              <Information>
                                {startup[startupField.field] ? "oui" : "non"}
                              </Information>
                            </Box>
                          )}
                      </Fragment>
                    )
                )}
              <Box>
                <Box display="flex" flexDirection="column" gap={1}>
                  <SectionSubTitle>Documents fournis:</SectionSubTitle>
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    gap={1}
                  >
                    {!startupFields.isLoading &&
                      !dataStartup.isLoading &&
                      renderFile()}
                    {!startupFields.isLoading &&
                      !dataStartup.isLoading &&
                      renderMultifiles()}
                  </Box>
                </Box>
              </Box>
            </ContainerHalf>
          </ContainerRight>
        </SectionContainer>
        <Box
          display="flex"
          justifyContent="space-between"
          mt="12px"
          width="90%"
        >
          <Button
            variant="text"
            startIcon={<Svg.ArrowLeft />}
            onClick={handleBack}
          >
            {width < 490 ? null : <ButtonText>Précédent</ButtonText>}
          </Button>

          <Button
            variant="contained"
            color="primary"
            boxshadow="0px 5px 5px rgba(239, 75, 18, 0.2)"
            width={width < 490 ? 110 : 190}
            onClick={validate}
          >
            Valider
          </Button>
        </Box>
      </Container>
      <ModalConfirmation
        isVisible={open}
        image={Images.Accepted}
        rightButtonText="Merci"
        rightButtonAction={handleDialogAction}
        children={isEligible ? modalItemAccpeted() : modalItemRejected()}
        onClickClose={handleClose}
      />
    </FormHandlerProvider>
  );
};

export default ValidationStartup;

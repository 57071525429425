import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
//Material UI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
//assets
import { Images } from "../../../assets";
//hooks
import { useWindowDimensions } from "../../../hooks";
//Helpers
import { getParameterByName, SnackbarHolder } from "../../../helpers";
//services
import { resetPassword } from "../../../services";
//Components
import { Form, Image, Button, Input } from "../../../components/Commons";
//Styles
import { Container, FormContainer } from "./resetPassword.styles";
//Types
import { ForgetPasswordState } from "./resetPassword.types";

const ResetPassword = () => {
  const navigate = useNavigate();
  const token = getParameterByName("token");
  const { height, width } = useWindowDimensions();
  //states
  const methods = useForm<ForgetPasswordState>({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });
  const dataWatch = methods.watch();
  //queries & mutations
  const reset = useMutation(resetPassword);
  //memos
  const fontSize = useMemo(() => (width < 490 ? 12 : 16), [width]);
  //functions
  const onSubmit: SubmitHandler<ForgetPasswordState> = async (data) => {
    try {
      const res = await reset.mutateAsync({
        token: token,
        password: data.password,
      });

      if (res?.status === 204) {
        SnackbarHolder.alert("success", "Mot de passer modifier avec succées.");

        navigate("/sign-in", { replace: true });

      }
    } catch (error) {
      SnackbarHolder.alert("error", "Une erreur c'est produite.");
    }
  };

  //render
  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Container>
          <FormContainer
            elevation={0}
            sx={{ maxHeight: height - 250, minHeight: height - 260 }}
          >
            <Image src={Images.ForgetPassword} width={100} />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width={400}
              gap={2}
              mt={4}
            >
              <Typography
                fontFamily="Mont"
                fontSize={fontSize}
                fontWeight={600}
                color="#616161"
                textAlign="center"
              >
                Créez un nouveau mot de passe!
              </Typography>
              <Input
                name="password"
                minWidth={360}
                label="Nouveau Mot de passe"
                type="password"
                rules={{
                  required: "This field is required",
                  pattern: {
                    value:
                      /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/gm,
                    message:
                      "Mot de passe doit être d'une longueur minimum de 8 caractères avec au moins 1 lettre minuscule, 1 lettre majuscule, 1 chiffre et un caractère spécial",
                  },
                }}
              />
              <Input
                name="confirmPassword"
                minWidth={360}
                label="Confirmer Mot de passe"
                type="password"
                rules={{
                  required: "This field is required",
                  validate: (value: string) =>
                    value === dataWatch.password || "Password non conform.",
                }}
              />
              <Button
                margin="32px 0 0 0"
                variant="contained"
                type="submit"
                width={190}
                boxshadow="0px 5px 5px rgba(239, 75, 18, 0.2)"
                onClick={methods.handleSubmit(onSubmit)}
              >
                Envoyer
              </Button>
            </Box>
          </FormContainer>
        </Container>
      </Form>
    </FormProvider>
  );
};
export default ResetPassword;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
//Material Ui
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
//Hooks
import { useWindowDimensions } from "../../../../hooks";
//components
import { ModalConfirmation } from "../../../../components";
import CGU from "../../../SharedPages/CGU";
//Styles
import {
  BottomText,
  ColoredText,
  Container,
  NormalText,
} from "./confirmChoise.styles";
import { useAuth } from "../../../../contextAPI";

const ConfirmChoice = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  //stats
  const [open, setOpen] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  //functions
  const handleCheck = () => {
    setIsChecked(true);
    setOpen(true);
  };
  const handleClose = () => {
    setIsChecked(false);
    setOpen(false);
  };
  const validate = () => {
    auth.handleCGUAcceptation(true);
    navigate("/user-form", { replace: true });
  };
  //renders
  const modalItem = () => {
    return <CGU />;
  };
  return (
    <>
      <Container
        maxHeight={width < 490 ? height - 290 : height - 420}
        minHeight={width < 490 ? height - 290 : height - 420}
        overflow="auto"
      >
        <NormalText
          mt={1}
          textAlign="center"
          sx={{ fontWeight: 700, color: "#EF4B12", fontSize: 24 }}
        >
          Bienvenue!
        </NormalText>
        <NormalText mt={1} textAlign="center">
          Vous êtes sur le point de postuler à Fuzé by Digital Africa, un
          dispositif d’investissement, réparti en petits tickets, pour soutenir
          les startups aux stades de l'idéation et du MVP. Testez votre
          éligibilité dès maintenant en remplissant ce formulaire !
        </NormalText>
        <NormalText textAlign="center">
          Gardez à portée de main les documents officiels présentant les statuts
          de votre entreprise ainsi que tout document donnant une description de
          vos activités.
        </NormalText>
        <NormalText textAlign="center">
          Soyez précis et exhaustif dans vos réponses, afin de permettre une
          analyse précise de vos accomplissements et vos besoins.
        </NormalText>
        <Box display="flex" alignItems="center">
          <FormGroup>
            <FormControlLabel
              disabled={open}
              control={
                <Checkbox
                  sx={{ color: "#EC6D3F" }}
                  color="primary"
                  checked={isChecked}
                  onChange={handleCheck}
                />
              }
              label={
                <ColoredText onClick={handleCheck}>
                  J'accepte les Conditions Générales d'Utilisation
                </ColoredText>
              }
            />
          </FormGroup>
        </Box>
        <ModalConfirmation
          isVisible={open}
          rightButtonText="J'accepte"
          rightButtonAction={validate}
          children={modalItem()}
          onClickClose={handleClose}
        />
        <BottomText>Temps estimé de remplissage: 30 min</BottomText>
      </Container>
    </>
  );
};

export default ConfirmChoice;

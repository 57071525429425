import { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
//Material Ui
import { Box } from "@mui/material";
//Helpers
import { getPageNameFromPathName } from "../../../helpers";
//Hooks
import { useWindowDimensions } from "../../../hooks";
//ContextAPI
import { useStepperContext } from "../../../contextAPI";
//Components
import { StepperList, TabsContainer } from "../../../components";

const Startup = () => {
  const stepper = useStepperContext();
  const location = useLocation();
  const navigate = useNavigate();
  const pathName = getPageNameFromPathName(location);
  const { width } = useWindowDimensions();

  //memos
  const listTabs = useMemo(
    () =>
      width < 490
        ? [
            { type: "startup", label: "Startup" },
            {
              type: "partner",
              label: "Structure d’accompagnement",
            },
          ]
        : [
            { type: "startup", label: "Vous êtes une startup" },
            {
              type: "partner",
              label: "Vous êtes une structure d’accompagnement",
            },
          ],
    [width]
  );
  const steps = useMemo(
    () =>
      width < 1024
        ? ["Profil ", "Startup", "Accom.", "Vérifi."]
        : [
            "Profil Répondant",
            "Startup",
            "Accompagnement",
            "Vérification de candidature",
          ],
    [width]
  );

  //states
  const [currenctTab, setCurrenctTab] = useState<string>("startup");

  //useEffects
  useEffect(() => {
    stepper.handleUpdateSteps(steps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    conformPathWithStep();
    confirmPathWithTabs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  //functions
  const handleTabClick = (type: string) => {
    if (type === "startup") {
      navigate("/", { replace: true });
    } else if (type === "partner") {
      navigate("/partner/confirm-choice-partner", { replace: true });
    }
  };
  const conformPathWithStep = () => {
    if (pathName[0] === "user-form") {
      stepper.handleStep(0);
      stepper.handleUpdateComplete({ 0: false });
    } else if (
      pathName[0] === "form-startup" ||
      pathName[0] === "edit-startup"
    ) {
      stepper.handleUpdateComplete({ 0: true });
      stepper.handleStep(1);
    } else if (
      pathName[0] === "add-support" ||
      pathName[0] === "edit-support"
    ) {
      stepper.handleUpdateComplete({ 0: true, 1: true });
      stepper.handleStep(2);
    } else if (pathName[0] === "validation") {
      stepper.handleUpdateComplete({ 0: true, 1: true, 2: true });
      stepper.handleStep(3);
    }
  };
  const confirmPathWithTabs = () => {
    if (pathName.length === 0) {
      setCurrenctTab("startup");
    }
  };

  //renders
  return (
    <TabsContainer
      tabList={listTabs}
      currenct={currenctTab}
      tabClick={(type: string) => handleTabClick(type)}
    >
      {pathName.length < 1 ||
      pathName[0] === "confirm-choice" ||
      pathName[0] === "no-draft" ? (
        <Outlet />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          gap={2}
          mt={width < 490 ? 2 : 4}
        >
          <StepperList
            steps={steps}
            activeStep={stepper.activeStep}
            completed={stepper.completed}
            handleStep={(step: number) => stepper.handleStep(step)}
          />
          <Outlet />
        </Box>
      )}
    </TabsContainer>
  );
};

export default Startup;

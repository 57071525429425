import { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
//Material Ui
import { Box } from "@mui/material";
//Hooks
import { useWindowDimensions } from "../../../hooks";
//Helpers
import {
  getPageNameFromPathName,
} from "../../../helpers";
//ContextAPI
import { useStepperContext } from "../../../contextAPI";
//Components
import { TabsContainer, StepperList } from "../../../components";

const Partner = () => {
  const stepper = useStepperContext();
  const location = useLocation();
  const navigate = useNavigate();
  const pathName = getPageNameFromPathName(location);
  const { width } = useWindowDimensions();


  //memos
  const listTabs = useMemo(
    () =>
      width < 490
        ? [
          { type: "startup", label: "Startup" },
          {
            type: "partner",
            label: "Structure d’accompagnement",
          },
        ]
        : [
          { type: "startup", label: "Vous êtes une startup" },
          {
            type: "partner",
            label: "Vous êtes une structure d’accompagnement",
          },
        ],
    [width]
  );
  const steps = useMemo(
    () =>
      width < 1024
        ? ["Partenaire ", "Plus d'info", "Vérification"]
        : [
          "Partenaire ",
          "Informations complémentaires",
          "Vérification de condidature",
        ],
    [width]
  );
  //states
  const [currenctTab, setCurrenctTab] = useState<string>("partner");

  //useEffects
  useEffect(() => {
    stepper.handleUpdateSteps(steps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    conformPathWithStep();
    confirmPathWithTabs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  //functions
  const handleTabClick = (type: string) => {
    if (type === "startup") {
      navigate("/", { replace: true });
    } else if (type === "partner") {
      navigate("/partner/confirm-choice-partner", { replace: true });
    }
  };
  const conformPathWithStep = () => {
    if (pathName[1] === "add-partner") {
      stepper.handleStep(0);
      stepper.handleUpdateComplete({ 0: false });
    } else if (
      pathName[1] === "form-company" ||
      pathName[1] === "edit-company"
    ) {
      stepper.handleStep(1);
      stepper.handleUpdateComplete({ 0: true });
    } else if (pathName[1] === "validation") {
      stepper.handleStep(2);
      stepper.handleUpdateComplete({ 0: true, 1: true });
    }
  };
  const confirmPathWithTabs = () => {
    if (pathName.length === 0) {
      setCurrenctTab("partner");
    }
  };

  return (
    <TabsContainer
      tabList={listTabs}
      currenct={currenctTab}
      tabClick={(type: string) => handleTabClick(type)}
    >
      {pathName.length < 1 ||
        pathName[1] === "confirm-choice-partner" ? (
        <Outlet />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          gap={2}
          mt={width < 490 ? 2 : 4}
        >
          <StepperList
            steps={steps}
            activeStep={stepper.activeStep}
            completed={stepper.completed}
            handleStep={(step: number) => stepper.handleStep(step)}
          />
          <Outlet />
        </Box>
      )}
    </TabsContainer>
  );
};

export default Partner;

import axios from "axios";

const createStartup = async (params: { data: any; token: string }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_DIRECTUS_URL}/items/startup`,
      params.data,
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      }
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};

const uploadStartupFile = async (params: { file: any; token: string }) => {
  const formData = new FormData();
  formData.append("title", params.file.name);
  formData.append("file", params.file);
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_DIRECTUS_URL}/files`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res;
  } catch (error: any) {
    return error.response;
  }
};

const saveAccompagnement = async (params: {
  data: any;
  startupId: string;
  token: string;
}) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_DIRECTUS_URL}/items/startup/${params.startupId}`,
      params.data,
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      }
    );
    return res;
  } catch (error: any) {
    return error.response;
  }
};

const getStartupById = async (params: {
  id: string;
  moreFields?: string;
  token: string;
}) => {
  const paramsFields = params.moreFields ? `?fields=*${params.moreFields}` : "";
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DIRECTUS_URL}/items/startup/${params.id}${paramsFields}`,
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      }
    );
    return res;
  } catch (error: any) {
    throw new Error(error);
  }
};

const getStartups = async (params: { token: string }) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DIRECTUS_URL}/items/startup`,
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      }
    );
    return res;
  } catch (error: any) {
    throw new Error(error);
  }
};

const updateStartup = async (params: {
  id: string;
  data: any;
  token: string;
}) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_DIRECTUS_URL}/items/startup/${params.id}`,
      params.data,
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      }
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};
const startupTrackPage = async (params: { data: any; token: string }) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_DIRECTUS_URL}/users/me/track/page`,
      params.data,
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      }
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};

const checkFlowsEligibility = async (params: { data: any; token: string }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_DIRECTUS_URL}/flows/trigger/${process.env.REACT_APP_TRIGGER_FLOW}`,
      params.data,
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      }
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export {
  createStartup,
  uploadStartupFile,
  saveAccompagnement,
  getStartupById,
  updateStartup,
  startupTrackPage,
  checkFlowsEligibility,
  getStartups,
};

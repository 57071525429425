import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
//Material Ui
import { Box, Typography } from "@mui/material";
//Asstes
import { Svg } from "../../../../assets";
//Hooks
import { useWindowDimensions } from "../../../../hooks";
//Components
import { CardChoice } from "../../../../components";
//Styles
import { Container, ContainerCondition } from "./choiceSelect.styles";

const ChoiceSelect = () => {
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  //functons
  const handleClickCard = () => {
    navigate("/confirm-choice", { replace: true });
  };
  //momes
  const boxDirection = useMemo(() => (width < 490 ? "column" : "row"), [width]);
  //renders
  const renderItem = () => {
    return (
      <CardChoice
        description="Je teste mon éligibilité et je dépose ma candidature"
        icon={<Svg.ToForm />}
        onCLick={() => handleClickCard()}
      />
    );
  };
  return (
    <Container
      maxHeight={height - 550}
      minHeight={height - 290}
      overflow="auto"
    >
      <Typography
        fontSize={width < 700 ? 16 : 18}
        fontFamily="Mont"
        fontWeight={500}
        color="#999999"
      >
        Plusieurs critères sont pris en compte pour évaluer l'éligibilité des
        candidats.
      </Typography>
      <ContainerCondition minHeight="auto">
        <Box display="flex" gap={1}>
          <Box>
            <Svg.StartupAge />
          </Box>
          <Typography
            fontSize={height < 700 ? 12 : 14}
            fontWeight={500}
            color="#616161"
            fontFamily="Mont"
          >
            la Sociétés a moins de 18 mois d’existence ou moins de 36 mois si elle génère des revenus ou si elle a levé des fonds.
          </Typography>
        </Box>
        <Box display="flex" gap={1}>
          <Box>
            <Svg.StartupLocation />
          </Box>
          <Typography
            fontSize={height < 700 ? 12 : 14}
            fontWeight={500}
            color="#616161"
            fontFamily="Mont"
          >
            Sociétés fondées ou co-fondées par au moins un citoyen des pays
            africains francophones.
          </Typography>
        </Box>
        <Box display="flex" gap={1}>
          <Box>
            <Svg.StartupTech />
          </Box>
          <Typography
            fontSize={height < 700 ? 12 : 14}
            fontWeight={500}
            color="#616161"
            fontFamily="Mont"
          >
            La présence d’une composante Tech dans le produit/modèle économique
            de la startup.
          </Typography>
        </Box>
        <Box display="flex" gap={1}>
          <Box>
            <Svg.StartupLanguage />
          </Box>
          <Typography
            fontSize={height < 700 ? 12 : 14}
            fontWeight={500}
            color="#616161"
            fontFamily="Mont"
          >
            La startup a des opérations (coût ou profit) dans un pays d’Afrique
            francophone.
          </Typography>
        </Box>
      </ContainerCondition>
      <Box display="flex" flexDirection={boxDirection} gap={4}>
        {renderItem()}
      </Box>
    </Container>
  );
};

export default ChoiceSelect;

import React, { FC, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useFormContext } from "react-hook-form";
//Material UI
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
//Assets
import { Svg } from "../../../assets";
//Styles
import {
  Container,
  ContainerFilled,
  FormHelper,
  Input,
  RemoveBtn,
  Text,
} from "./dropZoneFile.styles";
//Types
import { DropzoneFileProps, FileProps } from "./dropZoneFile.types";
import { useFormHandlerContext } from "../../../contextAPI";
import { IconButton } from "@mui/material";

const maxSize = 5242880;

const DropzoneFile: FC<DropzoneFileProps> = (props) => {
  const { name, rules, height, width, minWidth, message, onAddFiles } = props;
  const { control, setValue } = useFormContext();
  const dataForm = useFormHandlerContext();
  //functions
  const onDrop = useCallback((acceptedFiles: Array<File>) => {
    if (acceptedFiles.length > 0) {
      const newFile: FileProps = Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      });
      setValue(name, newFile);
      if (onAddFiles) {
        onAddFiles(newFile);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveFile = () => {
    setValue(name, null);
  };
  //render
  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept: {
        "application/*": [".pdf", ".xlsx", ".xls"],
      },
      disabled: dataForm?.loading,
      maxSize: maxSize,
    });

  const renderContent = (value: FileProps) => {
    if (isDragActive) {
      return <Text>Drop the files here ...</Text>;
    }
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Svg.DropZone />
        <Text mt={2}>{message}</Text>
      </Box>
    );
  };
  const handleError = () => {
    if (fileRejections.length > 0) {
      return fileRejections.map(({ file, errors }) => {
        return errors.map((e) => (
          <Typography color="red" fontSize={10}>
            Les Documents doivent être de type PDF ou exil et de taille de 5 Mb
            maximum
          </Typography>
        ));
      });
    }
  };
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value }, fieldState: { error } }) => (
        <React.Fragment>
          {handleError()}
          {value ? (
            <ContainerFilled
              {...getRootProps()}
              style={{
                height: height || 270,
                minHeight: "80px",
                width,
                minWidth,
              }}
            >
              <RemoveBtn>
                <IconButton onClick={handleRemoveFile}>
                  <Svg.Remove />
                </IconButton>
              </RemoveBtn>
              {value.type === "application/pdf" ? (
                <Svg.PDFIcon />
              ) : (
                <Svg.ExelIcon />
              )}
              <Typography fontSize={14} color="#B5B9C7">
                {value.name}
              </Typography>
              <Input {...getInputProps()} />
            </ContainerFilled>
          ) : (
            <Container
              {...getRootProps()}
              style={{
                // ...style,
                height: height || 270,
                minHeight: "80px",
                width,
                minWidth,
              }}
            >
              <Input {...getInputProps()} />
              {renderContent(value)}
            </Container>
          )}
          {error?.message && <FormHelper error>{error?.message}</FormHelper>}
        </React.Fragment>
      )}
    />
  );
};

export { DropzoneFile };

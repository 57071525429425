//Svg
import DropZone from "./Svg/DropZone";
import LogoSvg from "./Svg/Logo";
import ToFAQ from "./Svg/ToFAQ";
import ToForm from "./Svg/ToForm";
import ArrowRightBotton from "./Svg/ArrowRightBotton";
import Confirm from "./Svg/Confirm";
import ArrowLeft from "./Svg/ArrowLeft";
import DorpZoneAvatar from "./Svg/DropZoneAvatar";
import Remove from "./Svg/Remove";
import AddIconButton from "./Svg/AddIconButton";
import PDFIcon from "./Svg/PDFIcon";
import ExelIcon from "./Svg/ExelIcon";
import EditIcon from "./Svg/EditIcon";
import FooterSatoripopLogo from "./Svg/FooterSatoripopLogo";
import ConfirmInput from "./Svg/ConfirmInput";
import StartupAge from "./Svg/StartupAge";
import StartupLocation from "./Svg/StartupLocation";
import StartupTech from "./Svg/StartupTech";
import StartupLanguage from "./Svg/StartupLanguage";
import Twitter from "./Svg/Twitter";
import Facebook from "./Svg/Facebook";
import LinkedIn from "./Svg/LinkedIn";
import Notification from "./Svg/Notification";

//Images
import Logo from "./Images/logo.png";
import Fuse from "./Images/fuse.png";
import Background from "./Images/background.png";
import HeaderBackground from "./Images/header-background.png";
import SignIn from "./Images/sign-in.png";
import ForgetPassword from "./Images/forget-password.png";
import Otp from "./Images/otp.png";
import Rejected from "./Images/rejected.png";
import Accepted from "./Images/accepted.png";
import DefaultLogo from "./Images/defaut-logo.png";
import NotFound from "./Images/not-found.png";

export const Svg = {
  DropZone,
  LogoSvg,
  ToFAQ,
  ToForm,
  ArrowRightBotton,
  Confirm,
  ArrowLeft,
  DorpZoneAvatar,
  Remove,
  AddIconButton,
  PDFIcon,
  ExelIcon,
  EditIcon,
  FooterSatoripopLogo,
  ConfirmInput,
  StartupAge,
  StartupLocation,
  StartupTech,
  StartupLanguage,
  Twitter,
  Facebook,
  LinkedIn,
  Notification,
};

export const Images = {
  Logo,
  Fuse,
  Background,
  HeaderBackground,
  SignIn,
  ForgetPassword,
  Otp,
  Rejected,
  Accepted,
  DefaultLogo,
  NotFound,
};

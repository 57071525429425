import { Box, Typography } from "@mui/material";
import { useWindowDimensions } from "../../../hooks";
import { Table } from "./cgu.styles";

const CGU = () => {
  const { width } = useWindowDimensions();
  return (
    <>
      <Typography
        fontFamily="Mont"
        fontSize={width < 500 ? 16 : 20}
        color="#EF4B12"
        textAlign="center"
      >
        CONDITIONS GÉNÉRALES D’UTILISATION DES SERVICES DU PROGRAMME Fuzé
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start" mt={2}>
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Préambule
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        L’initiative DIGITAL AFRICA est née de la volonté du président de la
        République, Emmanuel Macron, de soutenir la dynamique entrepreneuriale
        et d’accompagner le développement de l’entrepreneuriat innovant en
        Afrique. DIGITAL AFRICA est une initiative collaborative et ouverte au
        service de l’entrepreneur africain.
      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        DIGITAL AFRICA, ci-après DIGITAL AFRICA, met, notamment en œuvre un
        programme Fuzé visant à accompagner les entrepreneurs de l’Afrique
        francophone, qui œuvrent dans le numérique et tout particulièrement dans
        certains secteurs prioritaires. Le programme Fuzé vise à octroyer un
        financement aux premières étapes du projet (phase d’idéation et
        d’amorçage).
      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        La plateforme numérique Fuzé, ci-après la “ Plateforme ”, permettra aux
        startup d’accéder aux informations sur le programme Fuzé et la procédure
        de sélection, et de soumettre leurs candidatures en ligne.
      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Les présentes Conditions Générales d’Utilisation (CGU) ont pour objet de
        fixer les conditions d'octroi de financement et de labellisation des
        partenaires du Programme Fuzé à l’Utilisateur, d’une part, et de définir
        les droits et obligations de DIGITAL AFRICA et l’Utilisateur, d’autre
        part. Ces Conditions générales d’utilisation (CGU) constituent un
        contrat entre DIGITAL AFRICA et l’Utilisateur dont le recueil des
        données n’est pas facultatif mais reste limité dans le temps (défini
        dans le volet “Base de données”).
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Définition
        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Pour les CGU des services du programme Fuzé , on entend par
        </Typography>
      </Box>
      <ol>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            Données : toute information transmise par la startup candidate au
            financement du programme Fuzé ainsi que les données relatives aux
            partenaires (les structures d’accompagnement partenaires).
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            Données à caractère personnel : Toute information se rapportant à
            une personne physique identifiée ou identifiable (ci-après dénommée
            «personne concernée»); est réputée être une «personne physique
            identifiable» une personne physique qui peut être identifiée,
            directement ou indirectement, notamment par référence à un
            identifiant, tel qu’un nom, un numéro d’identification, des données
            de localisation, un identifiant en ligne, ou à un ou plusieurs
            éléments spécifiques propres à son identité physique, physiologique,
            génétique, psychique, économique, culturelle ou sociale; (Art 4.1,
            Règlement Général de la Protection des Données à caractère personnel
            (RGPD)).
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            Formulaire : questionnaire de présentation et d’évaluation du
            candidat startup souhaitant bénéficier des services du Programme
            Fuzé ou de la structure d’accompagnement souhaitant devenir
            partenaire. Le formulaire doit être rempli par le candidat, qui
            deviendra Partenaire Fuzé .
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            Utilisateur : toute startup candidate au programme Fuzé , toute
            structure d’accompagnement souhaitant devenir Partenaire Fuzé ,
            toute startup sélectionnée pour recevoir les services du Programme
            Fuzé et tout Partenaire Fuzé , sont qualifiés d’utilisateurs.
          </Typography>
        </li>
      </ol>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Mentions légales
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Le site web du programme Fuzé est édité par Digital Africa, société par
        actions simplifiée unipersonnelle, au capital de 10 000 euros, dont le
        siège social est 151 rue Saint Honoré, à Paris 1er, immatriculée au
        registre du commerce et des sociétés de Paris sous le numéro 912 812 203
        représentée, représentée par Madame Stéphan-Eloïse GRAS agissant en sa
        qualité de Directrice Générale.
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Vous pouvez contacter Digital Africa, à l’adresse mail suivante: Fuzé
          @digital-africa.co
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Ce site web est hébergé par la Google, les données sont conservées et
        traitées en Europe en conformité avec le Règlement Général sur la
        Protection des Données.
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Objet
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Digital Africa lance Fuzé , un dispositif d’investissement pilote de 6,5
        ME, répartis en petits tickets, pour soutenir les startups francophones
        aux stades de l’idéation et du MVP. Destinés aux startups opérant en
        Afrique francophone, il s’agit de proposer des tickets pouvant aller
        jusqu’à 50KE pour permettre à des équipes proposant des solutions
        technologiques au service de l’économie réelle d’amorcer leur activité
        et de se focaliser sur leurs efforts de développement. Les présentes
        conditions générales d’Utilisation visent à préciser les conditions de
        sélection des startup qui bénéficieront des financements du Programme
        Fuzé y compris le processus digitalisé de candidature, ainsi que celui
        des structures accompagnantes.
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Acceptation des CGU
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        L’Utilisateur est réputé avoir pris connaissance des Conditions
        Générales d’utilisation (ci-après CGU) des opérations du Programme Fuzé
        , proposé par DIGITAL AFRICA et les avoir dûment acceptées. Les CGU sont
        mises à disposition de l’Utilisateur sur le site où elles sont
        directement consultables et imprimables par un lien direct en bas de la
        page d’accueil du site, mais peuvent également leur être communiquées
        sur demande écrite à l’adresse suivante : Fuzé @digital-africa.co Elles
        annulent et remplacent toutes les dispositions antérieures et
        constituent l’intégralité des droits et obligations des parties. L’envoi
        du formulaire d’acte de candidature implique l’acceptation entière et
        sans réserve des présentes CGU La non-acceptation des CGU conduit à
        annuler la demande de candidature de l’Utilisateur.
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Opérations déployées dans le cadre du programme Fuzé
        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Fuzé investit dans des startups fondées par des entrepreneurs
          africains.
        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Les montants sont les suivants
        </Typography>
      </Box>
      <ul>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            Pour un ticket ideation (stage 1) : 20K Eur
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            Pour un ticket de follow on (stage 2) : 30K Eur
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            Possibilité d’investir directement un ticket de 50K Eur sous
            condition de l’existence d’un co-investisseur prêt à investir au
            même moment du décaissement d’un ticket phase 2 Fuzé .
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            Il ne s’agit pas de dons ou de subventions remboursables mais bien
            d’investissement.
          </Typography>
        </li>
      </ul>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Pour identifier les start ups cibles, Fuzé s’appuie sur un réseau de
        partenaires de l’écosystème qui l'accompagne dans la détection de
        structures cibles d’investissement.
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Conditions de sélection:
        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Pour les start ups
        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Fuzé investit dans des startups fondées par des entrepreneurs
          africains.
        </Typography>
      </Box>
      <ul>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            La startup doit être une entreprise dotée d’une personnalité morale,
            basée dans l’un des 24 pays africains francophones.{" "}
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            L’ un des fondateurs doit obligatoirement être ressortissant d’un
            pays africain francophone{" "}
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            La startup doit présenter une Solution Tech ou une orientation
            technologique dans le business model
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            Durée d'existence de la startup : moins de 18 mois pour le premier 
            ticket de 20K euros. Cette condition ne s’appliquera pas aux startups 
            qui candidatent pour le deuxième tickets de 30K euros et le ticket 
            de 50K euros.
          </Typography>
        </li>
      </ul>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Fuzé se focalise sur les secteurs d’investissement à fort impact
        susceptibles d’améliorer la qualité de vie des africains via les
        nouvelles technologies.
      </Typography>

      <Box
        width="100%"
        display="flex"
        justifyContent="flex-start"
        fontSize={width < 500 ? 12 : 16}
      >
        <ul>
          <li>
            <Typography
              fontFamily="Mont"
              color="#000"
              textAlign="start"
              fontSize={width < 500 ? 12 : 16}
            >
              Santé
            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Mont"
              color="#000"
              textAlign="start"
              fontSize={width < 500 ? 12 : 16}
            >
              Accès à l’énergie
            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Mont"
              color="#000"
              textAlign="start"
              fontSize={width < 500 ? 12 : 16}
            >
              Accès aux services financiers
            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Mont"
              color="#000"
              textAlign="start"
              fontSize={width < 500 ? 12 : 16}
            >
              Mobilité
            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Mont"
              color="#000"
              textAlign="start"
              fontSize={width < 500 ? 12 : 16}
            >
              Agriculture
            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Mont"
              color="#000"
              textAlign="start"
              fontSize={width < 500 ? 12 : 16}
            >
              Education
            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Mont"
              color="#000"
              textAlign="start"
              fontSize={width < 500 ? 12 : 16}
            >
              FoodTech
            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Mont"
              color="#000"
              textAlign="start"
              fontSize={width < 500 ? 12 : 16}
            >
              Logistique
            </Typography>
          </li>
        </ul>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Pour les structures d’accompagnement
        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Les parcours utilisateurs:
        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          fontWeight={600}
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          - Parcours de l’utilisateur startup
        </Typography>
      </Box>
      <Box maxWidth={width < 500 ? "100%" : "auto"} overflow="auto">
        <Table>
          <tr>
            <th style={{ backgroundColor: "#000" }}></th>
            <th>I.</th>
            <th>II.</th>
            <th>III.</th>
            <th>IV.</th>
          </tr>
          <tr>
            <th>Étape clé</th>
            <th>Candidature</th>
            <th>Analyse</th>
            <th>Comité interne</th>
            <th>Diligence approfondie et</th>
          </tr>
          <tr>
            <td>1</td>
            <td>
              La startup remplit le formulaire et teste son éligibilité avant de
              déposer sa candidature.
            </td>
            <td>L’équipe Fuzé analyse les candidatures éligibles</td>
            <td>Le comité interne prend la décision d’investir ou non</td>
            <td>Due diligence: financière et légale.</td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              La structure d’accompagnement renseignée par la startup sera
              notifiée de la candidature et sera invitée à remplir une fiche de
              notation.
            </td>
            <td>
              L’avis de la structure d’accompagnement partenaire est pris en
              compte dans l’évaluation des dossiers.
            </td>
            <td>
              Un comité interne Fuzé sera le seul habilité à prendre une
              décision sur l’investissement dans la startup analysée; sur la
              base d’un processus d’analyse de risque et d’opportunité
              garantissant la neutralité.
            </td>
            <td>
              En phase de closing, d’autres documents seront demandés afin de
              finaliser les diligences.
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>
              Si la structure d’accompagnement est un partenaire Fuzé, elle
              soumet sa fiche de notation. Sinon, elle doit être approuvé par
              l’équipe Fuzé avant qu’elle puisse noter les startups.
            </td>
            <td>
              Les startups accompagnées dans le cadre d’un programme
              d’accompagnement viennent en premier ordre de priorité.
            </td>
            <td>
              La start up sera notifiée de la décision du comité interne.{" "}
            </td>
            <td>Signature d’un Accord d’Investissement Rapide.</td>
          </tr>
        </Table>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          fontWeight={600}
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          - Parcours de l’utilisateur “partenaire”:{" "}
        </Typography>
      </Box>
      <Box maxWidth={width < 500 ? "100%" : "auto"} overflow="auto">
        <Table>
          <tr>
            <th style={{ backgroundColor: "#000" }}></th>
            <th>I.</th>
            <th>II.</th>
            <th>III.</th>
            <th>IV.</th>
          </tr>
          <tr>
            <th>Étape clé</th>
            <th>Candidature</th>
            <th>Evaluation</th>
            <th>Contrat</th>
            <th>Interactions avec le partenaire </th>
          </tr>
          <tr>
            <td></td>
            <td>
              La structure d’accompagnement souhaitant devenir partenaire Fuzé ,
              remplit un formulaire de candidature en ligne.
            </td>
            <td>
              L’équipe Fuzé évalue la candidature de la structure en se basant
              sur la pertinence et le track record.{" "}
            </td>
            <td>
              Le partenaire sélectionné signera un Memorandum Of Understanding
              (MOU) et aura accès à un espace dédié sur la plateforme Fuzé .{" "}
            </td>
            <td>
              Toute startup candidate au programme Fuzé sera appelée à
              renseigner un contact référent dans la structure d’accompagnement.
              Cette dernière est automatiquement notifiée via email dès que la
              startup dépose sa candidature. Les partenaires seront aussi
              sollicités dans le cadre du follow-on ticket (Stage 2)
            </td>
          </tr>
        </Table>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Obligation de collaboration de bonne foi
        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          La bonne exécution des CGU et le bon déroulement des opérations du
          Programme Fuzé nécessitent une collaboration loyale, active permanente
          entre DIGITAL AFRICA et l’UTILISATEUR et de bonne foi.
        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Par conséquent, DIGITAL AFRICA et l’utilisateur s’engagent à :
        </Typography>
      </Box>
      <ul>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            s’impliquer activement dans l’exécution de leurs obligations,
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            s’abstenir de tout comportement susceptible d’affecter et/ou
            d’entraver l’exécution des opérations du Programme Fuzé
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            se fournir mutuellement dans un délai suffisant, compatible avec le
            bon respect des délais convenus, toutes informations et documents
            nécessaires à l’examen des candidatures,
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            s’alerter mutuellement le plus vite possible en cas de difficulté et
            se concerter pour mettre en place la meilleure solution possible
            dans les meilleurs délais.
          </Typography>
        </li>
      </ul>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Obligation de l’Utilisateur
        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Inscription pour candidater au Programme Fuzé
        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start" pl={10}>
        <Typography
          fontFamily="Mont"
          color="#000"
          fontWeight={600}
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          - Pour la startup candidate
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        La startup aura accès au questionnaire en ligne via le site Fuzé . Un
        compte utilisateur sera créé pour le fondateur, qui recevra un
        identifiant et un mot de passe. L’Utilisateur est responsable du
        maintien de la confidentialité de son identifiant et mot de passe et
        reconnaît expressément que toute utilisation depuis son compte sera
        réputée avoir été effectuée par lui-même. Dans l’hypothèse où
        l’Utilisateur constate que son compte est ou a été utilisé à son insu,
        il s’engage à en avertir DIGITAL AFRICA dans les plus brefs délais, à
        l’adresse email de contact Fuzé @digital-africa.co.
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start" pl={10}>
        <Typography
          fontFamily="Mont"
          color="#000"
          fontWeight={600}
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          - Pour le Partenaire candidat{" "}
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Un compte utilisateur sera créé pour le Partenaire. Il recevra un
        identifiant et un mot de passe. Le partenaire bénéficiera d’un espace
        personnel où il pourra accéder à la liste des startups candidates qu’il
        accompagne ainsi qu’au scoring sheets qui lui permettront de noter les
        startups candidates.
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start" pl={10}>
        <Typography
          fontFamily="Mont"
          color="#000"
          fontWeight={600}
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          - Obligation de loyauté{" "}
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        L’Utilisateur s’engage à respecter les principes de loyauté,
        transparence dans ses relations avec DIGITAL AFRICA Toute information
        fournie par l’Utilisateur dans le cadre de la sélection et de manière
        générale dans tout échange avec l’équipe du Programme Fuzé et DIGITAL
        AFRICA, doit être licite, authentique, loyale, actualisée et refléter la
        réalité.
      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        L’Utilisateur s’engage à ne pas communiquer un contenu protégé par un
        droit de propriété intellectuelle dont l’Utilisateur n’est pas titulaire
        des droits. L’Utilisateur s’engage à coopérer de bonne foi lors des
        vérifications (due diligence) effectuées par l’équipe du Programme Fuzé
        .
      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Toute communication de contenu illicite entraînera la résiliation de la
        relation contractuelle.
      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        En conséquence, l’Utilisateur s’engage à indemniser DIGITAL AFRICA de
        tout préjudice qu’elle subirait en cas de manquement à ses obligations,
        et à prendre à sa charge tous les dommages-intérêts, ainsi que les
        frais, charges et dépens auxquels DIGITAL AFRICA pourrait être condamné.{" "}
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start" pl={10}>
        <Typography
          fontFamily="Mont"
          color="#000"
          fontWeight={600}
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          - Comportements prohibés{" "}
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Le Partenaire Fuzé n’agit pas en qualité de mandataire de DIGITAL
        AFRICA, dès lors, il ne peut s’engager vis-à-vis de la startup
        sélectionnée au nom et pour le compte de DIGITAL AFRICA.
      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Il est strictement interdit à l’Utilisateur d’utiliser les
        investissements du programme Fuzé pour
      </Typography>
      <ul>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            l’exercice d’une activité illégale, frauduleuse ou portant atteinte
            aux droits ou à la sécurité des tiers ,
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            l’atteinte à l’ordre public ou la violation des lois et règlements
            en vigueur,
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            l’intrusion dans le système informatique d’un tiers ou toute
            activité de nature à nuire, contrôler, interférer, ou intercepter
            tout ou partie du système informatique d’un tiers, en violer
            l’intégrité ou la sécurité,
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            l’aide ou l’incitation, sous quelque forme et de quelque manière que
            ce soit, à un ou plusieurs des actes et activités décrits ci-dessus,
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            plus généralement toute pratique détournant les Services à des fins
            autres que celles pour lesquelles ils ont été conçus ;
          </Typography>
        </li>
      </ul>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Pour tout manquement aux présentes CGU.ou de violation répétée de
        celles-ci, DIGITAL AFRICA se réserve le droit de suspendre l’accès de
        l'utilisateur, auteur du manquement, à tout ou partie des opérations de
        Fuzé , avec effet immédiat.
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start" pl={10}>
        <Typography
          fontFamily="Mont"
          fontWeight={600}
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          - Suspension temporaire d’accès au compte Utilisateur ou Fermeture
          définitive{" "}
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        En cas de manquement aux obligations des CGU, DIGITAL AFRICA se réserve
        la possibilité de suspendre l’accès au compte utilisateur ou de le
        fermer définitivement en cas de manquement grave constaté aux présentes
        CGU. DIGITAL AFRICA s’engage à notifier par courriel au titulaire du
        compte les griefs retenus à son encontre et la motivation de la décision
        soit de suspension temporaire de l’accès au compte utilisateur, soit sa
        fermeture définitive.
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Obligation de Digital Africa:
        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start" pl={10}>
        <Typography
          fontFamily="Mont"
          color="#000"
          fontWeight={600}
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          - Devoir de transparence{" "}
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        DIGITAL AFRICA est soumis à une obligation de transparence sur la
        procédure de sélection des utilisateurs. Si l’équipe du Programme Fuzé
        considère que les critères de sélection ne sont pas satisfaits, elle en
        informe le candidat par courriel et motive le refus de la candidature
        sur demande. Si la startup ne remplit pas les critère primaires
        d’éligibilité, elle sera informée automatiquement via la plateforme à la
        fin du questionnaire. Il sera indiqué dans quel délai la startup pourra
        re-candidater en fonction du défaut de compliance aux critères primaires
        d’éligibilité.
      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        La structure accompagnante, dont la candidature a été rejetée, sera
        automatiquement informée de la décision de refus et des fondements de
        son rejet, via la plateforme. Il sera indiqué dans quel délai, elle
        pourra re-candidater en fonction des motifs de refus.
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start" pl={10}>
        <Typography
          fontFamily="Mont"
          color="#000"
          fontWeight={600}
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          - Limitation de responsabilité de DIGITAL AFRICA{" "}
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        DIGITAL AFRICA s’engage à faire les meilleurs efforts pour apporter tout
        le soin possible à l’exécution de ses obligations conformément à l’état
        de l’Art et dans le respect des réglementations applicables, ce que
        l’Utilisateur reconnaît et accepte expressément.
      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Sa responsabilité est exclusivement limitée à la fourniture des
        Opérations selon les modalités décrites aux présentes, à l’exclusion de
        toute autre prestation.
      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        En aucun cas, DIGITAL AFRICA ne pourra être tenue pour responsable tant
        à l’égard de l’Utilisateur qu’à l’égard de ses partenaires et des tiers,
        pour tout dommage imprévisible ou pour tout dommage indirect, qu’il soit
        matériel ou immatériel, causé par l'utilisation de la plateforme de
        candidature utilisée par l’Utilisateur ainsi que toute perte ou
        détérioration d’informations pour lesquelles DIGITAL AFRICA ne peut être
        tenue pour responsable. Tout dommage subi par un tiers est un dommage
        indirect et ne donne pas lieu en conséquence à indemnisation. De manière
        expresse, DIGITAL AFRICA et l’Utilisateur conviennent que les
        limitations de responsabilité continuent à s’appliquer même en cas de
        résiliation des CGU
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Protection de la propriété intellectuelle{" "}
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        L'utilisateur conserve la propriété intellectuelle de ses actifs
        immatériels. Aucune propriété intellectuelle n’est concédée à Digital
        Africa, sauf convention contraire entre les Parties.{" "}
      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        DIGITAL AFRICA détient les droits de propriété intellectuelle sur les
        formulaires et tout document du programme Fuzé . Elle est également
        producteur de base de données, comme précisé ci-après.{" "}
      </Typography>

      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Politique de protection des données à caractère personnel{" "}
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Les informations transmises par l’Utilisateur dans le cadre de l’examen
        de sa candidature et dans les échanges avec l’équipe du Programme Fuzé
        susceptibles d’être considérées comme des données à caractère personnel
        au sens du Règlement Général sur la protection des données à caractère
        personnel (RGPD) seront traitées conformément à la politique de
        protection des données personnelles de DIGITAL AFRICA.
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Confidentialité{" "}
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Toutes les informations, toutes les données, tous les livrables et/ou
        tout le savoir-faire, couverts ou non par les lois de propriété
        intellectuelle, quelle qu’en soient la forme et la nature (commerciale,
        industrielle, technique, financière, etc…), échangées entre les Parties
        ou dont elles auraient connaissance lors de la phase de sélection et
        l’implémentation du Programme Fuzé seront considérées comme
        confidentielles (ci-après les “Informations Confidentielles”). Chacune
        des Parties s’engage à n’utiliser les Informations Confidentielles que
        dans le cadre de l’exécution du Programme Fuzé , à protéger les
        Informations Confidentielles et à ne pas les divulguer à des tiers
        autres que ses employés, collaborateurs, filiales et sous- traitants
        ayant besoin d’en connaître pour l’exécution des CGU sans l’autorisation
        préalable et écrite de l’autre Partie. Les Parties s’engagent à prendre
        toutes les mesures nécessaires pour faire en sorte que leurs employés,
        collaborateurs, filiales et sous-traitants ayant accès aux Informations
        Confidentielles soient informés du caractère confidentiel des
        informations communiquées et respectent les obligations découlant de la
        présente clause.
      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Chacune des Parties sera dégagée de ses obligations de confidentialité
        en ce qui concerne toutes les informations :
      </Typography>
      <ol>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            qui étaient en possession de cette Partie avant leur divulgation par
            l’autre Partie sans qu’une telle possession ne résulte directement
            ou indirectement de la divulgation non autorisée de ces informations
            par un tiers,
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            qui font partie du domaine public à la date d’acceptation du Contrat
            ou qui tomberaient dans le domaine public après cette date sans que
            la cause en soit imputable au non- respect par cette Partie de ses
            obligations de confidentialité au titre des CGU,
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            qui ont été élaborées de façon indépendante par cette Partie,
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            dont la divulgation est exigée par la loi ou une autorité judiciaire
            ou administrative compétente ou est nécessaire à la défense des
            intérêts de l’une ou l’autre des Parties dans le cadre d’une action
            judiciaire.
          </Typography>
        </li>
      </ol>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Les Parties s’engagent à respecter les obligations résultant du présent
        article pendant toute la durée du Programme Fuzé ainsi que pendant 1 an
        suivant sa cessation. En aucun cas, une copie des documents contenant
        des informations confidentielles ne pourra être conservée par une Partie
        sauf accord exceptionnel et écrit de l’autre Partie.
      </Typography>

      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Base de données{" "}
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Les données transmises par l’Utilisateur pour examiner sa candidature au
        Programme Fuzé sont conservées en base active pendant toute la durée du
        parcours de l'utilisateur, jusqu’à la sélection finale. En cas de
        sélection, l’ensemble des données seront conservées pendant toute la
        durée du programme et pendant 6 mois à l’expiration des opérations du
        programme à titre probatoire. Elles seront ensuite hébergées dans une
        base de données, dont Digital Africa, est le producteur de base de
        données au sens de l’article L 341-1 du Code de la Propriété
        Intellectuelle (CPI). Toutes les données à caractère personnel seront
        supprimées. Les données protégées par un droit de propriété
        intellectuelle ne pourront pas être intégrées à la base, sauf si
        l’Utilisateur cède les droits d’exploitation à DIGITAL AFRICA.
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Elles pourront être traitées par DIGITAL AFRICA pour les finalités
          suivantes :
        </Typography>
      </Box>
      <ul>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            L’amélioration de la prédictibilité des programmes d’investissement
            soutenus par Digital Africa, notamment par l’utilisation d’outils
            d’intelligence artificielle ;
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            L’amélioration qualitative et quantitative de la définition des
            politiques publiques d’investissement dans le cadre de son périmètre
            :
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            L’amélioration de la connaissance du marché et des besoins des
            acteurs.{" "}
          </Typography>
        </li>
      </ul>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        En aucun cas l’Utilisateur ne pourra revendiquer un droit de propriété
        intellectuelle sur les documents de la base de données. Les candidatures
        refusées seront conservées pendant une durée de 2 ans afin d’affiner les
        critères de sélection du Programme Fuzé et d’optimiser la procédure de
        sélection des candidats à ce programme. A l’issue de cette période, les
        candidatures refusées seront anonymisées.
      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Un droit de rectification peut être exercé en contactant DIGITAL AFRICA
        lorsque :
      </Typography>

      <ul>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            une erreur ou une inexactitude est avérée,
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            une actualisation ou une complétion est nécessaire
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            la présence de données dont la collecte, l’utilisation, la
            communication ou la conservation est jugée illicite
          </Typography>
        </li>
      </ul>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Convention de preuve
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        L’Utilisateur reconnaît que les fichiers électroniques (comprenant les
        informations fournies durant le parcours d’inscription) ainsi que les
        courriels seront considérés comme les preuves consenties des échanges et
        des communications entre l'Utilisateur et DIGITAL AFRICA. L’acceptation
        du traitement des informations communiquées, cadrées dans le temps et
        dans la limite de l’activité décrite par DIGITAL AFRICA est conditionnée
        à la validation de ces CGU. L’Utilisateur s’engage à ne pas contester
        leur force probante et reconnaît la même valeur probante aux écrits et
        documents électroniques transmis dans le cadre de l’implémentation des
        CGU qu’aux écrits et documents papiers. L’Utilisateur reconnaît et
        accepte, qu’après authentification, toute manifestation de volonté par
        le biais de l’utilisation des fonctionnalités proposées par la
        Plateforme DIGITAL AFRICA constitue une signature électronique et
        caractérise la preuve du consentement au sens des dispositions de
        l’article 1353 Code Civil.
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Durée
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Les présentes CGU s’appliquent pendant toute la durée du Programme Fuzé
        et tout au long de la dernière mise en oeuvre et jusqu’à sa
        finalisation.
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Modifications des CGU des opérations Fuzé
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        DIGITAL AFRICA se réserve la faculté de modifier à tout moment les
        présentes conditions générales. L’Utilisateur sera informé des
        modifications des CGU par mail, au moins 15 jours avant leur entrée en
        vigueur. Les conditions générales ainsi modifiées s’appliqueront
        immédiatement à tout Utilisateur dès leur mise en ligne sur la
        Plateforme. Tout utilisateur qui a recours à la plateforme
        postérieurement à l’entrée en vigueur des conditions générales modifiées
        est réputé avoir accepté ces modifications qui lui seront pleinement
        applicables.
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Force majeure{" "}
        </Typography>
      </Box>
      <ul>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            Événement de force majeure: Aucune des Parties ne pourra être tenue
            pour responsable d’un manquement quelconque à ses obligations
            contractuelles, si elle a été empêchée d’exécuter son obligation par
            un événement de force majeure tel que défini à l’article 1218 du
            Code civil.
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            qui font partie du domaine public à la date d’acceptation du Contrat
            ou qui tomberaient dans le domaine public après cette date sans que
            la cause en soit imputable au non- respect par cette Partie de ses
            obligations de confidentialité au titre des CGU,
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            Notification: Dans ce cas, la Partie invoquant la force majeure
            notifiera à l’autre Partie, par lettre recommandée avec accusé de
            réception, dans les meilleurs délais, la survenance d’un tel
            événement et la nécessaire extension des dates limites d’exécution
            de ses obligations.
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            Empêchement temporaire: Si l’empêchement est temporaire, l’exécution
            de l’obligation s’en trouvera suspendue jusqu’à que la Partie
            invoquant la force majeure ne soit plus empêchée par l'événement de
            force majeure. La Partie invoquant la force majeure devra tenir
            l’autre Partie informée et s’engage à faire de son mieux pour
            limiter la durée de la suspension.
          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            Résiliation pour Force Majeure: Dans le cas où la suspension se
            poursuivrait au-delà d’un délai de trois (3) mois, chacune des
            Parties aura la possibilité de résilier les CGU sans indemnité en
            notifiant à l’autre Partie sa décision par lettre recommandée avec
            accusé de réception. Si l’empêchement est définitif, les CGU sont
            résiliées de plein droit et les parties sont libérées de leurs
            obligations dans les conditions prévues à l’article 1351-1 du Code
            civil.
          </Typography>
        </li>
      </ul>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Renonciation{" "}
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Le fait pour l’une des Parties de ne pas se prévaloir de l’une
        quelconque des obligations visées aux CGU ne saurait être interprété
        ultérieurement comme une renonciation à l’obligation en cause.
        L’Utilisateur final renonce irrévocablement à toute demande,
        réclamation, droit ou action à l’encontre de DIGITAL AFRICA et qui
        serait formulée plus de douze (12) mois après le fait générateur.
      </Typography>

      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Intégralité{" "}
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Les Conditions Générales d’Utilisation expriment l’intégralité des
        obligations des parties. En cas de difficultés d’interprétation entre
        l’un quelconque des titres figurant en tête des clauses, et l’une
        quelconque des clauses, les titres seront déclarés inexistants.
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Nullité{" "}
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Si une ou plusieurs stipulations des CGU étaient tenues pour non valides
        ou déclarées comme telle en application d’une loi ou à la suite d’une
        décision définitive d’une juridiction compétente, les autres
        stipulations garderont leurs forces et portées.
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Notification{" "}
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Toutes les notifications, pour être valides, devront avoir été
        effectuées à l’adresse de domiciliation par lettre recommandée avec
        accusé de réception.
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          Loi et attribution de juridiction{" "}
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Les Conditions Générales d’Utilisation sont régies par la loi française.
        En cas de litige et après tentative de procédure amiable, compétence
        expresse est attribuée au Tribunal du siège social de DIGITAL AFRICA
        nonobstant la pluralité de défendeur ou appel en garantie, y compris
        pour les procédures sur requête ou d’urgence.
      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        Copyright © 2022 Digital Africa. Tous droits réservés.
      </Typography>
    </>
  );
};

export default CGU;

import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { useMemo } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
//Components
import { FooterGeneral, HeaderForm } from "../../components";
//ContextApi
import { useAuth } from "../../contextAPI";
//Helpers
import { getPageNameFromPathName, Startup } from "../../helpers";
//hooks
import { useWindowDimensions } from "../../hooks";
//Services
import { getStartups, getUserMe } from "../../services";
//Styles
import { Container } from "./authLayout.styles";

//consts
const pathNames = ["sign-in", "forget-password", "reset-password"];

const AuthLayout = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = getPageNameFromPathName(location);
  const { height, width } = useWindowDimensions();

  //queries & mutaions
  const getUser = useQuery(
    ["user", auth.token],
    () => {
      if (!auth.token) return;
      return getUserMe({ token: auth.token });
    },
    {
      enabled: auth.token ? true : false,
    }
  );

  const dataStartups = useQuery(
    ["startups", auth.token],
    () => {
      if (!auth.token) return;
      return getStartups({
        token: auth.token,
      });
    },
    {
      enabled: auth.token ? true : false,
    }
  );

  //memos
  const buttonText = useMemo(
    () => (!pathNames.includes(pathName[0]) ? "Connexion" : "S’inscrire"),
    [pathName]
  );
  const text = useMemo(
    () => (!pathNames.includes(pathName[0]) ? "Déjà membre" : "Nouveau Membre"),
    [pathName]
  );
  const myStartup = useMemo(
    () => dataStartups?.data?.data?.data[0],
    [dataStartups]
  );
  const user = useMemo(() => getUser?.data?.data?.data, [getUser]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deviceHeight = useMemo(() => _.clone(height), []);

  //functions
  const handleSignIn = () => {
    if (!pathNames.includes(pathName[0])) {
      navigate("/sign-in", { replace: true });
    } else {
      navigate("/", { replace: true });
    }
  };

  //renders
  if (auth.token && getUser.isFetched && dataStartups.isFetched) {
    let redirectTo: string = "/form-startup";
    if (user.role === Startup && myStartup) {
      redirectTo = `/no-draft/${myStartup.id}`;
    }
    return <Navigate to={redirectTo} state={{ from: location }} replace />;
  }
  return (
    <Container minHeight={width < 1024 ? deviceHeight : "100vh"}>
      <HeaderForm
        buttonText={buttonText}
        text={text}
        buttonAction={handleSignIn}
      />
      <Outlet />
      <FooterGeneral />
    </Container>
  );
};
export default AuthLayout;

import { useState, useMemo, Fragment, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
//Material Ui
import { Box, IconButton, Link, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
//ContextAPI
import {
  FormHandlerProvider,
  useAuth,
  useStepperContext,
} from "../../../../contextAPI";
//Assets
import { Images, Svg } from "../../../../assets";
//Hooks
import { useWindowDimensions } from "../../../../hooks";
//services
import {
  getFields,
  getPartnerById,
  getUserMe,
  updateUserMe,
} from "../../../../services";
//components
import {
  Button,
  Form,
  Image,
  Input,
  ModalConfirmation,
} from "../../../../components";
//Helpers
import { SnackbarHolder, sortFieldsArray } from "../../../../helpers";
//Styles
import {
  ButtonText,
  Container,
  ContainerLeft,
  ContainerHalf,
  ContainerRight,
  Information,
  SectionContainer,
  SectionSubTitle,
  SectionTitle,
  Labeltext,
  DocumentBox,
  DocumentText,
} from "./validationPartner.styles";
//Types
import { ParamsProps, ValidationPartnerState } from "./validationPartner.types";

const exeptionFields = [
  "id",
  "status",
  "logo",
  "user_created",
  "user_updated",
  "date_updated",
  "date_created",
  "list-m2m",
  "due_diligence",
  "accompaniment",
  "other_partner_name",
  "contact_ref",
  "other_partner_email",
  "partner",
  "name",
  "country",
  "website",
  "file",
  "select-dropdown-m2o",
  "team_total_number",
  "mentors_numbers",
];
const ValidationPartner = () => {
  const auth = useAuth();
  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();
  const stepper = useStepperContext();
  const params: ParamsProps = useParams();

  //states
  const [open, setOpen] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [paramsFields, setParamsFields] = useState<string>("");

  const methods = useForm<ValidationPartnerState>({
    defaultValues: {
      email: "",
      first_name: "",
      last_name: "",
      function: "",
    },
  });
  //queries & mutations
  const partnerFields = useQuery(
    ["fields-partner", auth.token, params.id],
    () => {
      if (!auth.token) return;
      return getFields("partner", auth.token);
    },
    {
      onSuccess(fields) {
        let str = "";
        let arrayOfFiles = fields?.data?.data.filter(
          (field: any) => field.meta.interface === "file"
        );
        arrayOfFiles.forEach((element: any) => {
          if (!exeptionFields.includes(element.field)) {
            str = str + `,${element.field}.*`;
          }
        });
        setParamsFields(str);
      },
    }
  );
  const getUser = useQuery(
    ["user", auth.token],
    () => {
      if (!auth.token) return;
      return getUserMe({ token: auth.token });
    },
    {
      onSuccess(data) {
        methods.reset({
          email: data?.data?.data.email,
          first_name: data?.data?.data.first_name,
          last_name: data?.data?.data.last_name,
          function: data?.data?.data.function,
        });
      },
    }
    //
  );

  const dataPartner = useQuery(
    ["partner", params.id],
    () => {
      if (!params.id || !auth.token) {
        return;
      }
      if (paramsFields === "") return;
      return getPartnerById({
        id: params.id,
        moreFields: paramsFields,
        token: auth.token,
      });
    },
    {
      onSuccess(partner) {
        if (partner?.data.data.status !== "En attente") {
          window.location.href = `${process.env.REACT_APP_REDIRECT_NO_DRAFT_PARTNER}`;
        }
      },
    }
  );

  const updateUser = useMutation(updateUserMe);
  //memos
  const fields = useMemo(
    () => partnerFields?.data?.data?.data.sort(sortFieldsArray),
    [partnerFields]
  );
  const partner = useMemo(() => dataPartner?.data?.data?.data, [dataPartner]);
  const user = useMemo(() => getUser?.data?.data?.data, [getUser]);
  const boxdirections = useMemo(
    () => (width < 490 ? "column" : "row"),
    [width]
  );
  const boxjustify = useMemo(
    () => (width < 490 ? "center" : "flex-start"),
    [width]
  );

  //useEffects
  useEffect(() => {
    dataPartner.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFields]);

  //funstions
  const handleClose = () => {
    setOpen(false);
  };
  const handleVisibility = () => {
    setIsEditable(!isEditable);
  };

  const onFailSubmit = (data: any) => {
    SnackbarHolder.alert("error", "Des champs obligatoires non remplis");
  };
  const onSubmit: SubmitHandler<ValidationPartnerState> = async (data) => {
    if (!auth.token) return;
    const dataToSend = {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      function: data.function,
    };
    try {
      const res = await updateUser.mutateAsync({
        data: dataToSend,
        token: auth.token,
      });
      if (res?.status === 200) {
        getUser.refetch();
        SnackbarHolder.alert("success", "Données mis à jour avec succés");
      } else {
        SnackbarHolder.alert("error", "Une erreur s'est produite");
      }
    } catch (error: any) {
      SnackbarHolder.alert("error", "Une erreur s'est produite");
    }
    handleVisibility();
  };
  const validate = () => {
    auth.signOut(`${process.env.REACT_APP_BACK_OFFICE}`);
  };

  const handleBack = () => {
    navigate(`/partner/edit-company/${params.id}`, { replace: true });
    stepper.handleBack();
  };
  const handleEdit = () => {
    navigate(`/partner/edit-company/${params.id}`, { replace: true });
  };
  // const handleClickDigitalAfrica = () => {
  //   /**redirect to middle Office */
  //   window.location.href = `${process.env.REACT_APP_MIDDLE_OFFICE}`;
  // };
  //renders
  const modalItemAccpeted = () => {
    return (
      <>
        <Typography
          fontFamily="Mont"
          fontSize={20}
          fontWeight={600}
          color="#000"
          textAlign="center"
        >
          Nous vous remercions de l’intérêt que vous portez à Fuzé!
        </Typography>
        <Typography
          fontFamily="Mont"
          fontSize={16}
          fontWeight={400}
          textAlign="center"
          color="#000"
        >
          Nous allons traiter votre candidature, si elle remplit les critères
          d'éligibilité et est sélectionnée, nous reprendrons contact avec vous
          pour les prochaines étapes.
        </Typography>
        <Box display="flex" flexDirection="row" gap={1}>
          <Link
            target="_blank"
            href="https://www.linkedin.com/company/digitalafrica/"
          >
            <Svg.LinkedIn />
          </Link>
          <Link
            target="_blank"
            href="https://www.facebook.com/Digitalafrica.co/"
          >
            <Svg.Facebook />
          </Link>
          <Link target="_blank" href="https://twitter.com/digitalafrica__">
            <Svg.Twitter />
          </Link>
        </Box>
      </>
    );
  };

  const renderPersonalInformation = () => {
    if (isEditable) {
      return (
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <SectionTitle>Informations personnel</SectionTitle>
              <IconButton
                onClick={methods.handleSubmit(onSubmit, onFailSubmit)}
              >
                <Svg.ConfirmInput />
              </IconButton>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              maxHeight={width > 490 ? (height - 550) / 2 : "auto"}
              minHeight={width > 490 ? (height - 550) / 2 : "auto"}
              pt={1}
              overflow="auto"
            >
              <Input
                name="last_name"
                minWidth={200}
                label={
                  <Box display="flex">
                    <Labeltext color="rgba(43, 45, 50, 0.64)">Nom</Labeltext>
                    <Labeltext color="#EF4B12">*</Labeltext>
                  </Box>
                }
                rules={{
                  required: "Ce champ est requis.",
                }}
              />
              <Input
                name="first_name"
                minWidth={200}
                label={
                  <Box display="flex">
                    <Labeltext color="rgba(43, 45, 50, 0.64)">Prénom</Labeltext>
                    <Labeltext color="#EF4B12">*</Labeltext>
                  </Box>
                }
                rules={{
                  required: "Ce champ est requis.",
                }}
              />
              <Input
                name="email"
                minWidth={200}
                label={
                  <Box display="flex">
                    <Labeltext color="rgba(43, 45, 50, 0.64)">Email</Labeltext>
                    <Labeltext color="#EF4B12">*</Labeltext>
                  </Box>
                }
                rules={{
                  required: "Ce champ est requis.",
                }}
              />
              <Input
                name="function"
                minWidth={200}
                label={
                  <Box display="flex">
                    <Labeltext color="rgba(43, 45, 50, 0.64)">
                      URL Linkedin
                    </Labeltext>
                    <Labeltext color="#EF4B12">*</Labeltext>
                  </Box>
                }
                rules={{
                  required: "Ce champ est requis.",
                }}
              />
            </Box>
          </Form>
        </FormProvider>
      );
    } else if (!isEditable && !getUser.isLoading) {
      return (
        <>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <SectionTitle>Contact clé</SectionTitle>
            <IconButton onClick={handleVisibility}>
              <Svg.EditIcon />
            </IconButton>
          </Box>
          {user && (
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              maxHeight={width > 490 ? (height - 570) / 2 : "auto"}
              minHeight={width > 490 ? (height - 570) / 2 : "auto"}
              pt={1}
            >
              <Box display="flex" flexDirection="row" gap={1}>
                <SectionSubTitle>Nom:</SectionSubTitle>
                <Information>{user.last_name}</Information>
              </Box>
              <Box display="flex" flexDirection="row" gap={1}>
                <SectionSubTitle>Prénom:</SectionSubTitle>
                <Information>{user.first_name}</Information>
              </Box>
              <Box display="flex" flexDirection="row" gap={1}>
                <SectionSubTitle>Email:</SectionSubTitle>
                <Information>{user.email}</Information>
              </Box>
              <Box display="flex" flexDirection="row" gap={1}>
                <SectionSubTitle>Fonction:</SectionSubTitle>
                <Information>{user.function}</Information>
              </Box>
            </Box>
          )}
        </>
      );
    }
  };

  const renderFile = () => {
    if (fields.length < 0) return;
    const arrayofFiles = fields.filter(
      (field: any) => field.meta.interface === "file"
    );
    if (!partner) return;
    // eslint-disable-next-line array-callback-return
    return arrayofFiles.map((file: any, index: number) => {
      if (partner[file.field] != null) {
        return (
          <DocumentBox
            key={index}
            target="_blank"
            href={`${process.env.REACT_APP_DIRECTUS_URL}/assets/${
              partner[file.field].id
            }?access_token=${process.env.REACT_APP_ACCESS_TOKEN}`}
          >
            {partner[file.field].type === "application/pdf" ? (
              <Svg.PDFIcon />
            ) : (
              <Svg.ExelIcon />
            )}
            <DocumentText>{partner[file.field].title}</DocumentText>
          </DocumentBox>
        );
      }
    });
  };

  if (
    partnerFields.isLoading ||
    getUser.isLoading ||
    dataPartner.isLoading ||
    !getUser.isFetched ||
    !dataPartner.isFetched
  ) {
    return <CircularProgress />;
  }
  return (
    <FormHandlerProvider
      loading={
        partnerFields.isLoading || getUser.isLoading || dataPartner.isLoading
      }
    >
      <Container
        minHeight={width < 490 ? height - 380 : height - 420}
        maxHeight={width < 490 ? height - 380 : height - 420}
      >
        <SectionContainer
          minHeight={width < 490 ? height - 380 : height - 420}
          maxHeight={width < 490 ? height - 380 : height - 420}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <ContainerLeft
              maxHeight={width > 490 ? (height - 460) / 2 : "auto"}
              minHeight={width > 490 ? (height - 460) / 2 : "auto"}
              overflow="auto"
            >
              {renderPersonalInformation()}
            </ContainerLeft>
            <ContainerLeft
              maxHeight={width > 490 ? (height - 460) / 2 : "auto"}
              minHeight={width > 490 ? (height - 460) / 2 : "auto"}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <SectionTitle>Équipe</SectionTitle>
              </Box>
              {!dataPartner.isLoading && partner && (
                <>
                  <Box display="flex" flexDirection="row" gap={1}>
                    <SectionSubTitle>Nbrs de l’équipe :</SectionSubTitle>
                    <Information>
                      {dataPartner.isLoading ? "" : partner.team_total_number}
                    </Information>
                  </Box>
                  <Box display="flex" flexDirection="row" gap={1}>
                    <SectionSubTitle>Nbrs de mentors :</SectionSubTitle>
                    <Information>
                      {dataPartner.isLoading ? "" : partner.mentors_numbers}
                    </Information>
                  </Box>
                </>
              )}
            </ContainerLeft>
          </Box>
          <ContainerRight
            maxHeight={width > 1060 ? height - 450 : "auto"}
            minHeight={width > 1060 ? height - 450 : "auto"}
          >
            <ContainerHalf>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <SectionTitle>Présentation générale</SectionTitle>
                <IconButton onClick={() => handleEdit()}>
                  <Svg.EditIcon />
                </IconButton>
              </Box>
              <Box></Box>
              {!dataPartner.isLoading && partner && (
                <Box
                  display="flex"
                  flexDirection={boxdirections}
                  alignItems={boxjustify}
                  gap={1}
                >
                  <Image
                    src={
                      partner.logo
                        ? `${process.env.REACT_APP_DIRECTUS_URL}/assets/${partner.logo}?access_token=${process.env.REACT_APP_ACCESS_TOKEN}`
                        : Images.DefaultLogo
                    }
                    height={80}
                    width={80}
                    borderRadius={50}
                  />
                  <Box display="flex" flexDirection="column">
                    <SectionSubTitle>
                      {dataPartner.isLoading ? "" : partner.name}
                    </SectionSubTitle>
                    <Box display="flex" flexDirection="row" gap={1}>
                      <SectionSubTitle>Pays d’origine:</SectionSubTitle>
                      <Information>
                        {dataPartner.isLoading ? "" : partner.country}
                      </Information>
                    </Box>
                    {partner.website && (
                      <Box display="flex" flexDirection="row" gap={1}>
                        <SectionSubTitle>URL:</SectionSubTitle>
                        <Information>
                          <Link
                            target="_blank"
                            href={dataPartner.isLoading ? "#" : partner.website}
                          >
                            {dataPartner.isLoading ? "" : partner.website}
                          </Link>
                        </Information>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
              {!partnerFields.isLoading &&
                !dataPartner.isLoading &&
                partner &&
                fields.map(
                  (partnerField: any, index: number) =>
                    !exeptionFields.includes(partnerField.field) && (
                      <Fragment key={index}>
                        {partnerField.meta.interface === "input" &&
                          partner[partnerField.field] !== "" &&
                          partner[partnerField.field] !== 0 &&
                          partner[partnerField.field] != null && (
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap={1}
                              flexWrap="wrap"
                            >
                              <SectionSubTitle>
                                {partnerField.meta.note}
                              </SectionSubTitle>
                              <Information>
                                {partner[partnerField.field]}
                              </Information>
                            </Box>
                          )}
                        {partnerField.meta.interface === "datetime" &&
                          partner[partnerField.field] && (
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap={1}
                              flexWrap="wrap"
                            >
                              <SectionSubTitle>
                                {partnerField.meta.note}
                              </SectionSubTitle>
                              <Information>
                                {partner[partnerField.field]}
                              </Information>
                            </Box>
                          )}
                        {partnerField.meta.interface ===
                          "select-multiple-checkbox" &&
                          partner[partnerField.field] && (
                            <Box display="flex" flexDirection="column" gap={1}>
                              <SectionSubTitle>
                                {partnerField.meta.note}
                              </SectionSubTitle>
                              <Information>
                                {new Intl.ListFormat("en-GB", {
                                  style: "narrow",
                                  type: "unit",
                                }).format(partner[partnerField.field].text)}
                              </Information>
                            </Box>
                          )}
                        {partnerField.meta.interface === "input-multiline" &&
                          partner[partnerField.field] && (
                            <Box display="flex" flexDirection="column" gap={1}>
                              <SectionSubTitle>
                                {partnerField.meta.note}
                              </SectionSubTitle>
                              <Information>
                                {partner[partnerField.field]}
                              </Information>
                            </Box>
                          )}
                        {partnerField.meta.interface === "select-dropdown" &&
                          partner[partnerField.field] && (
                            <Box display="flex" flexDirection="column" gap={1}>
                              <SectionSubTitle>
                                {partnerField.meta.note}
                              </SectionSubTitle>
                              <Information>
                                {partner[partnerField.field]}
                              </Information>
                            </Box>
                          )}
                        {partnerField.meta.interface === "boolean" &&
                          partner[partnerField.field] && (
                            <Box display="flex" flexDirection="column" gap={1}>
                              <SectionSubTitle>
                                {partnerField.meta.note}
                              </SectionSubTitle>
                              <Information>
                                {partner[partnerField.field] ? "oui" : "non"}
                              </Information>
                            </Box>
                          )}
                      </Fragment>
                    )
                )}
              <Box>
                {!partnerFields.isLoading &&
                  !dataPartner.isLoading &&
                  partner &&
                  fields.map(
                    (partnerField: any, index: number) =>
                      !exeptionFields.includes(partnerField.field) && (
                        <Fragment key={index}>
                          {partnerField.meta.interface === "file" &&
                            partner[partnerField.field] && (
                              <Box
                                display="flex"
                                flexDirection="column"
                                gap={1}
                              >
                                <SectionSubTitle>
                                  Documents fournis:
                                </SectionSubTitle>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  flexWrap="wrap"
                                  gap={1}
                                >
                                  {!partnerFields.isLoading &&
                                    !dataPartner.isLoading &&
                                    renderFile()}
                                </Box>
                              </Box>
                            )}
                        </Fragment>
                      )
                  )}
              </Box>
            </ContainerHalf>
          </ContainerRight>
        </SectionContainer>
        <Box display="flex" justifyContent="space-between" mt={2} width="100%">
          <Button
            variant="text"
            startIcon={<Svg.ArrowLeft />}
            onClick={handleBack}
          >
            {width < 490 ? null : <ButtonText>Précédent</ButtonText>}
          </Button>
          <Button
            variant="contained"
            color="primary"
            width={width < 490 ? 110 : 190}
            boxshadow="0px 5px 5px rgba(239, 75, 18, 0.2)"
            onClick={() => setOpen(true)}
          >
            Valider
          </Button>
        </Box>
      </Container>
      <ModalConfirmation
        isVisible={open}
        image={Images.Accepted}
        rightButtonText="Merci"
        rightButtonAction={validate}
        children={modalItemAccpeted()}
        onClickClose={handleClose}
      />
    </FormHandlerProvider>
  );
};

export default ValidationPartner;
